import { createAction } from 'redux-actions';

export const data_loaded = createAction('ACCOUNT_DATA_LOADED');

export const request = createAction('ACCOUNT_REQUEST');
export const success = createAction('ACCOUNT_SUCCESS');
export const failure = createAction('ACCOUNT_FAILURE');

export const register_user_request = createAction('REGISTER_USER_REQUEST');
export const register_user_success = createAction('REGISTER_USER_SUCCESS');
export const register_user_failure = createAction('REGISTER_USER_FAILURE');

export const delete_user_image_request = createAction('DELETE_USER_IMAGE_REQUEST');
export const delete_user_image_success = createAction('DELETE_USER_IMAGE_SUCCESS');
export const delete_user_image_failure = createAction('DELETE_USER_IMAGE_FAILURE');

export const reset_password_token_validation_request = createAction('RESET_PASSWORD_TOKEN_VALIDATION_REQUEST');
export const reset_password_token_validation_success = createAction('RESET_PASSWORD_TOKEN_VALIDATION_SUCCESS');
export const reset_password_token_validation_failure = createAction('RESET_PASSWORD_TOKEN_VALIDATION_FAILURE');

export const login_request = createAction('LOGIN_REQUEST');
export const login_success = createAction('LOGIN_SUCCESS');
export const login_failure = createAction('LOGIN_FAILURE');

export const forgot_password_request = createAction('FORGOT_PASSWORD_REQUEST');
export const forgot_password_success = createAction('FORGOT_PASSWORD_SUCCESS');
export const forgot_password_failure = createAction('FORGOT_PASSWORD_FAILURE');

export const user_update_image_request = createAction('USER_UPDATE_IMAGE_REQUEST');
export const user_update_image_success = createAction('USER_UPDATE_IMAGE_SUCCESS');
export const user_update_image_failure = createAction('USER_UPDATE_IMAGE_FAILURE');

export const put_user_request = createAction('PUT_USER_REQUEST');
export const put_user_success = createAction('PUT_USER_SUCCESS');
export const put_user_failure = createAction('PUT_USER_FAILURE');

export const is_browser_request = createAction('IS_BROWSER_REQUEST');

export const logout_request = createAction('LOGOUT_REQUEST');

export function fetch(id = 1) {
  id = 1;
  return (dispatch, getState, { axios }) => {
    dispatch(request(id));
    return axios
      .get(`https://jsonplaceholder.typicode.com/posts/${id}`)
      .then(({ data }) => dispatch(success(data)))
      .then(() => true);
  };
}
