import * as RepresentativeActions from '../actions/representative';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Representative {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Change representative type
   * @param {*} param0
   */
  changeRepresentativeType({
    resolve = noop,
    reject = noop,
    headers = {},
    representativeId,
    data = {},
    type = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .patch(`${API_URL}/representative/${representativeId}/`, data, autorization)
        .then((response) => {
          dispatch(RepresentativeActions.put_representative_success({
            representativeId,
            type,
            data,
          }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Check token for invite
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  deleteRepresentative({
    resolve = noop,
    reject = noop,
    headers = {},
    representativeId,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .delete(`${API_URL}/representative/${representativeId}/`, autorization)
        .then((response) => {
          dispatch(RepresentativeActions.delete_representative_success({
            representativeId,
          }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Check representative invite token
   * @param resolve
   * @param reject
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  checkRepresentativeInviteToken({
    resolve = noop,
    reject = noop,
    data = {},
  }) {
    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/representative/token-check/`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get representative types
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @returns {function(*, *, {axios: *})}
   */
  getRepresentativeTypes({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    const query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/representative/types/${query}`, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Invite representative confirm
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param token
   * @returns {function(*, *, {axios: *})}
   */
  inviteRepresentativeConfirm({
    resolve = noop,
    reject = noop,
    data = {},
    token,
  }) {
    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/representative/confirm/${token}/`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Invite representative to company
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  inviteRepresentative({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/representative/invite/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Invite representative to company
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  resendInvite({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    representative_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/representative/${representative_id}/resend/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new Representative();
