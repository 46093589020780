import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Files {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Delete file
   * @param resolve
   * @param reject
   * @param headers
   * @param file_id
   * @returns {function(*, *, {axios: *})}
   */
  deleteFile({
    resolve = noop,
    reject = noop,
    headers = {},
    file_id,
  }) {
    const autorization = {
      headers: { Authorization: `JWT ${headers.token}` },
    };

    return (dispatch, getState, { axios }) => {
      return axios
        .delete(`${API_URL}/files/${file_id}/`, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          console.log(error);
        });
    };
  }

  /**
   * Delete file
   * @param resolve
   * @param reject
   * @param headers
   * @param file_id
   * @returns {function(*, *, {axios: *})}
   */
  downloadFile({
    resolve = noop,
    reject = noop,
    file_id=null,
    filename=null
  }) {
    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/files/${file_id}/`, { responseType: 'blob' })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          Logger.logResponseError(error);
          console.log(error);
        });
    };
  }

  /**
   * Upload
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  upload({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = {
      headers: { Authorization: `JWT ${headers.token}` },
      'Content-Type': 'multipart/form-data',
    };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/upload/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new Files();
