import { ToastContainer, Slide } from "react-toastify";
import { Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import './assets/scss/app.scss';
// import config from './config';
import routes from './routes';
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
// Components
import Sidebar from './containers/Sidebar/Sidebar';
import ReactDOM from 'react-dom';
// Routes
import Error404 from './containers/Error404';
import { CookiesProvider } from 'react-cookie';
import store from './redux/configureStore';
import { axiosApiInstance }  from "./redux/axios";
import PrivateRoute from './privateRoute';
import { Auth } from './services/';


const App = () => {
  const account = useSelector((state) => state.account)
  // const axios = useSelector((state) => state.axios)
  const dispatch = useDispatch()

  useEffect(() => {
    const token = localStorage.getItem("token");
    if(token && axiosApiInstance) {
      const auth = Auth.isAuth({
        resolve: () => {
        },
        reject: () => {
        }
      });
      auth(dispatch, { axios: axiosApiInstance });
    }
  }, [axiosApiInstance]);

  return (
      <div className="wrapper">
        <Helmet key={window.location.href}>
          <html lang="en" />
          <meta charSet="utf-8" />
          {/* <meta name="dmca" content={config('htmlPage.defaultTitle')} />
          <meta name="description" content={config('htmlPage.description')} /> */}
          <meta httpEquiv="Content-Security-Policy" content="img-src * 'self' data: blob: ;media-src * data: blob: filesystem:;" />
          <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests"/>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="msapplication-TileColor" content="#2b2b2b" />
          <meta name="msapplication-TileImage" content="/favicons/mstile-144x144.png"/>
          <base href="/" />
          <link rel="icon" type="image/png" href="/favicons/apple-touch-icon-152x152.png" sizes="152x152"/>
          <link rel="icon" type="image/png" href="/favicons/apple-touch-icon-144x144.png" sizes="144x144"/>
          <link rel="icon" type="image/png" href="/favicons/apple-touch-icon-120x120.png" sizes="120x120"/>
          <link rel="icon" type="image/png" href="/favicons/apple-touch-icon-114x114.png" sizes="114x114"/>
          <link rel="icon" type="image/png" href="/favicons/apple-touch-icon-76x76.png" sizes="76x76"/>
          <link rel="icon" type="image/png" href="/favicons/apple-touch-icon-72x72.png" sizes="72x72"/>
          <link rel="icon" type="image/png" href="/favicons/apple-touch-icon-60x60.png" sizes="60x60"/>
          <link rel="icon" type="image/png" href="/favicons/apple-touch-icon-57x57.png" sizes="57x57"/>
          <link rel="icon" type="image/png" href="/favicons/favicon-196x196.png" sizes="196x196"/>
          <link rel="icon" type="image/png" href="/favicons/favicon-128x128.png" sizes="128x128"/>
          <link rel="icon" type="image/png" href="/favicons/favicon-96x96.png" sizes="96x96"/>
          <link rel="icon" type="image/png" href="/favicons/favicon-32x32.png" sizes="32x32"/>
          <link rel="icon" type="image/png" href="/favicons/favicon-16x16.png" sizes="16x16"/>
          <link rel="icon" type="image/ico" href="/favicons/favicon.ico" sizes="16x16"/>
          <link rel="preconnect" href="https://fonts.gstatic.com"></link>
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
          <link rel="manifest" href="/manifest.json" />
        </Helmet>

        <div>
          { account && account.user && <Sidebar />}
          <ToastContainer transition={ Slide }/>
          <Switch>
              { routes.auth.map(route => <Route key={route.name} {...route} account={ account } />) }
              { routes.ignored_links.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
              { routes.filters.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
              { routes.companies.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
              { routes.senders.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
              { routes.orders.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
              { routes.domains.map(route =>
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route> 
                ) 
              }
              { routes.histories.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
              { routes.events.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
              { routes.notices.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
              { routes.account.map(route => 
                <Route key={route.name} exact path={route.path} element={<PrivateRoute />}>
                  <Route key={route.name} {...route} account={ account } />
                </Route>
                ) 
              }
            <Route component={ Error404 } />
          </Switch>
        </div>
      </div>
  );
}

const WrapperApp = DragDropContext(HTML5Backend)(App);

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <BrowserRouter>
        <WrapperApp/>
      </BrowserRouter>
    </CookiesProvider>
  </Provider>,
  document.getElementById('app')
);