import CompanyRoutes from './companies';
import HistoryRoutes from './histories';
import EventRoutes from './events';
import DomainRoutes from './domains';
import NoticeRoutes from './notices';
import SenderRoutes from './senders';
import OrderRoutes from './orders';
import AuthRoutes from './auth';
import AccountRoutes from './account';
import FiltersRoutes from './filters';
import IgnoredLinksRoutes from './ignoredlinks';

const routes = {
  companies: CompanyRoutes,
  histories: HistoryRoutes,
  account: AccountRoutes,
  senders: SenderRoutes,
  domains: DomainRoutes,
  notices: NoticeRoutes,
  events: EventRoutes,
  orders: OrderRoutes,
  auth: AuthRoutes,
  filters: FiltersRoutes,
  ignored_links: IgnoredLinksRoutes,
};

export default routes;
