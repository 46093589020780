import * as AccountActions from './../../actions/account';
import { handleActions } from 'redux-actions';

const initialState = {
  reset_password_token_valid_loading: false,
  reset_password_token_valid_loaded: false,
  loading_forgot_password: false,
  loaded_authentication: false,
  isAuthenticated: false,
  data_loaded: false,
  is_browser: false,
  user: null,
};

// Reducer
const reducer = handleActions(
  {
    // Update user data
    [AccountActions.put_user_success]: (state, { payload: { data, user_id } }) => {
      const user = state.user;
      let payload = {};

      if (user && user.id === user_id) {
        payload = { ...data };
      }

      return { ...state,
        user: {
          ...user,
          ...payload,
        },
      };
    },

    // Load user data
    [AccountActions.data_loaded]: (state, { payload }) => {
      return { ...state,
        data_loaded: true,
        ...payload
      };
    },

    // Update user image
    [AccountActions.user_update_image_success]: (state, { payload: { image } }) => {
      let user = {
        ...state.user,
        info: {
          ...state.user.info,
          image
        }
      };

      return {
        ...state,
        user
      };
    },

    // Delete user image
    [AccountActions.delete_user_image_success]: (state) => {
      let user = {
        ...state.user,
        info: {
          ...state.user.info,
          image: null
        }
      };

      return {
        ...state,
        user
      };
    },

    // Validate token from reset password
    [AccountActions.reset_password_token_validation_request]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [AccountActions.reset_password_token_validation_success]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [AccountActions.reset_password_token_validation_failure]: (state, { payload }) => {
      return { ...state, ...payload };
    },

    // Is browser request
    [AccountActions.is_browser_request]: (state) => {
      return { ...state, is_browser: true };
    },
    // Forgot password
    [AccountActions.forgot_password_request]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [AccountActions.forgot_password_success]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    [AccountActions.forgot_password_failure]: (state, { payload }) => {
      return { ...state, ...payload };
    },
    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...state,
        isAuthenticated: false,
        data_loaded: false,
        user: null,
      };
    },

    // Test if user it's auth
    [AccountActions.request]: (state, { payload }) => {
      return {
        ...state,
        authentication_loading: false,
        ...payload,
      };
    },
    [AccountActions.success]: (state, { payload }) => {
      return {
        ...state,
        authentication_loading: false,
        loaded_authentication: true,
        ...payload,
      };
    },
    [AccountActions.failure]: (state, { payload }) => {
      return {
        ...state,
        authentication_loading: false,
        loaded_authentication: true,
        ...payload,
      };
    },
  },
  initialState,
);

export default reducer;
