import * as IgnoredLinksActions from '../actions/ignored_links';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class IgnoredLinksSevice {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Add company event
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  // createIgnoredLink({ resolve = noop, reject = noop, headers = {}, data = {}  }) {
  //   const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
  //   return (dispatch, getState, { axios }) => {
  //     return axios
  //       .post(`${API_URL}/companies/create/`, data, autorization)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         Logger.logResponseError(error);
  //         reject(error);
  //       });
  //   };
  // }

  /**
   * Get companies
   * @param resolve
   * @param reject
   * @param headers
   * @param dispatchAction
   * @param per_page
   * @param page
   * @returns {function(*, *, {axios: *})}
   */
  getIgnoredLinkNotices({
    resolve = noop,
    reject = noop,
    headers = {},
    per_page = 20,
    page = 1,
    dispatchAction = true,
    search,
    link_id = null,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(IgnoredLinksActions.get_ignoredlinks_request({}));
      }

      let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

      if (search) {
        query += `&search=${search}`;
      }

      return axios
        .get(`${API_URL}/ignored-links/${link_id}/notices${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(IgnoredLinksActions.get_ignoredlinksnotices_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(
              IgnoredLinksActions.get_ignoredlinksnotices_failure({}),
            );
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get companies
   * @param resolve
   * @param reject
   * @param headers
   * @param dispatchAction
   * @param per_page
   * @param page
   * @returns {function(*, *, {axios: *})}
   */
  getIgnoredLinks({
    resolve = noop,
    reject = noop,
    headers = {},
    per_page = 20,
    page = 1,
    dispatchAction = true,
    search,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(IgnoredLinksActions.get_ignoredlinks_request({}));
      }

      let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

      if (search) {
        query += `&search=${search}`;
      }

      return axios
        .get(`${API_URL}/ignored-links/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(IgnoredLinksActions.get_ignoredlinks_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(
              IgnoredLinksActions.get_ignoredlinks_failure({}),
            );
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  createIgnoredLink({
    resolve = noop,
    reject = noop,
    data = {},
    headers = {},
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/ignored-links/`, data, autorization)
        .then((response) => {
          console.log(response);
          if (dispatchAction) {
            // dispatch(IgnoredLinksActions.get_ignoredlinks_success({
            //   results: response.data,
            // }));
          }
          resolve(response);
        })
        .catch((error) => {
          
          if (dispatchAction) {
            // dispatch(
            //   IgnoredLinksActions.get_ignoredlinks_failure({}),
            // );
          }
          Logger.logResponseError(error);
          error = error.response.data.message ? error.response.data.message : error.response.data.link
          reject(error);
        });
    };
  }

  getLink({
    resolve = noop,
    reject = noop,
    headers = {},
    link_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/ignored-links/${ link_id }/`, autorization)
        .then((response) => {
          dispatch(IgnoredLinksActions.get_ignored_link_success({
            ignored_link: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  deleteLink({
    resolve = noop,
    reject = noop,
    headers = {},
    link_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .delete(`${API_URL}/ignored-links/${ link_id }/`, autorization)
        .then((response) => {
          dispatch(IgnoredLinksActions.get_ignored_link_success({
            ignored_link: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new IgnoredLinksSevice();
