/* eslint-disable */
import * as yup from 'yup';
import { GetCardType } from './utils';

export const EMAIL_REGEX = /(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-011\013\014\016-\177])*")@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+[A-Z]{2,20}\.?$/i;

// Validate email
export const email = (value) =>
  value && !EMAIL_REGEX.test(value)
    ? 'Should be a valid email'
    : undefined;

// Validate required
const required = (value) =>
  value || typeof value === 'number' ? undefined : 'Required';

// List validation
export const validationSchema = yup.object().shape({
  username: yup.string().required('Email is required!').email('Should be a valid email'),
  email: yup.string().required('Email is required!').email('Should be a valid email'),
  password: yup.string().required('Password is required!').min(8, 'Ensure this field has at least 8 characters.'),
  code: yup.string().required('Code is required!'),
  secret: yup
    .string()
    .required('Field is required!')
    .test('match-text', 'Not match text!', (value) => value && value.toLowerCase() === 'delete'),
  // Validate registration
  confirmPassword: yup
    .string()
    .required('Confirm password is required!')
    .oneOf([yup.ref('password'), null], 'Confirm password does not match with password!')
    .min(8, 'Ensure this field has at least 8 characters.'),
  // Validate change password form
  current_password: yup.string().required('Current password is required!'),
  new_password: yup
    .string()
    .required('New password is required!')
    .min(8, 'Ensure this field has at least 8 characters.')
    .notOneOf([yup.ref('current_password')], 'New password cannot be the same as the current password.'),
  confirm_password: yup
    .string()
    .required('Confirm password is required!')
    .oneOf([yup.ref('password'), null, yup.ref('new_password')], 'Confirm password does not match with password!'),
  // Sender validate
  sender: yup.string().required('Sender is required!'),
  received_date: yup.string().required('Date is required!'),
  // Validate add company registration  id form
  dmca_public_id: yup.string().required('Public id is required!'),
  dmca_registration_id: yup.string().required('Registration id is required!'),
  // Validate add new company form
  name: yup.string().required('Company (or individual) name is required!'),
  address: yup.string().required('Street is required!'),
  city: yup.string().required('City is required!'),
  zip: yup.string().required('Zip is required!'),
  state: yup.string().required('State is required!'),
  country: yup.string().required('Country is required!'),
  // Validate add new company form
  line1: yup.string().required('Street is required!'),
  // Validate credit card form
  card_name: yup.string().required('Name on card is requireddd!'),
  number: yup
    .string()
    .required('Card number is required!')
    .test('valid-number', 'Card number not valid!', value => {
      const number = value ? value.split(' ').join('') : '';
      return number.length >= 14;
    }),
  expire_date: yup
    .string()
    .required('Expiration date is required!')
    .test('valid-date', 'Not valid date (ex: MM/YYYY)', value => {
      return value && value.length >= 9;
    }),
  cvv: yup
    .string()
    .required('Security code is required!')
    .test('valid-cvv', 'Security code need to have 3 numbers!', (value, context) => {
      const cardType = GetCardType(context.parent.number || '');
      return value && value.length >= (cardType === 'amex' ? 4 : 3);
    }),
  // Validate URL
  url: yup.string().required('Url is required!').matches(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/, 'Not valid url!'),
  notes: yup.string().required('Notes is required!'),
  // Change user info form
  first_name: yup.string().required('First name is required!'),
  // Xero
  xero_contact_id: yup.string().required('Contact id is required!'),
  status: yup.string().required('No selected status'),
  role: yup.string().required('Role is required!'),
});

const validationSchemaAddEvent = yup.object().shape({
  email: yup.string().required('Email is required!').email('Should be a valid email'),
  name: yup.string().required('Name is required!'),
  company_name: yup.string().required('Company name is required!'),
  line1: yup.string().required('Address line 1 is required!'),
  city: yup.string().required('City is required!'),
  state: yup.string().required('State is required!'),
  country: yup.string().required('Country is required!'),
  event_type: yup.string().required('Event type is required!'),
});

// General validation function
export const validateForm = (values, schema) => {
  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (validationError) {
    return validationError.inner.reduce((errors, err) => {
      errors[err.path] = err.message;
      return errors;
    }, {});
  }
};

// Validate login form
export const validateLoginForm = (values) => validateForm(values, validationSchema.pick(['username', 'password']));

// Validate register form
export const validateDeleteUserForm = (values) => validateForm(values, validationSchema.pick(['secret']));

// Validate reset password form
export const validateResetPasswordForm = (values) => validateForm(values, validationSchema.pick(['password', 'confirm_password']));

// Validate register form
export const validateRegisterForm = (values) => validateForm(values, validationSchema.pick(['email', 'password', 'confirmPassword']));

// Validate register confirm form
export const validateRegisterConfirmForm = (values) => validateForm(values, validationSchema.pick(['code']));

export const validateUpdateNoticeForm = (values) => {
  const errors = {};
  return errors;
};

// Validate add note form
export const validateAddNoteForm = (values) => validateForm(values, validationSchema.pick(['sender', 'received_date']));

export const validateSendNoticeEmailForm = (values) => {
  const errors = {};
  return errors;
};

// Validate add company registration  id form
export const validateAddCompanyRegistrationIdForm = (values) => validateForm(values, validationSchema.pick(['dmca_public_id', 'dmca_registration_id']));

// Validate add new company form
export const validateNewCompanyForm = (values) => validateForm(values, validationSchema.pick(['name', 'address', 'city', 'country']));

// Validate add new company form
export const validateCompanyInfoForm = (values) => validateForm(values, validationSchema.pick(['name', 'line1', 'city', 'country']));

// Validate add notice contact in add notice sender
export const validateAddNoticeContactForm = values => {};

// Validate invite member form
export const validateCompanyInviteMemberForm = (values) => validateForm(values, validationSchema.pick(['email', 'role']));

// Validate credit card form
export const validateCreditCardForm = (values) => validateForm(values, validationSchema.pick(['card_name', 'number', 'expire_date', 'cvv']));

// Validate forgot form
export const validateForgotForm = (values) => validateForm(values, validationSchema.pick(['email']));

// Validate add order note form
export const validateAddOrderNoteForm = (values) => validateForm(values, validationSchema.pick(['notes']));

// Validate add order url form
export const validateAddOrderUrlForm = (values) => validateForm(values, validationSchema.pick(['url']));

// Validate add domain form
export const validateAddDomainForm = (values) => validateForm(values, validationSchemaAddEvent.pick(['name']));

// Change user info form
export const validateChangeUserInfoForm = (values) => validateForm(values, validationSchema.pick(['first_name']));

// Change billing info
export const validateCompanyBillingInfoForm = (values) => validateForm(values, validationSchema.pick(['email', 'line1', 'zip', 'country']));

// Validate change domain form
export const validateChangeDomainEmailForm = (values) => validateForm(values, validationSchema.pick(['email']));

export const validateUpdateFilterForm = (values) => {
  return {};
};

// Validate change notice status form
export const validateChangeNoticeStatusForm = (values) => {
  const errors = {};

  if (!values.status) {
    errors.status = 'Field is required!';
  }

  return errors;
};

export const validateChangeUserStatus = (values) => validateForm(values, validationSchema.pick(['status']));

// Validate change password form
export const validateChangePasswordForm = (values) => validateForm(values, validationSchema.pick(['current_password', 'new_password', 'confirm_password']));

// Validate add company event form
export const validateAddCompanyEventForm = (values) => validateForm(values, validationSchemaAddEvent.pick(['company_name', 'line1', 'city', 'state', 'country', 'event_type']));

export const validateAddXeroContact = (values) => validateForm(values, validationSchemaAddEvent.pick(['xero_contact_id']));
