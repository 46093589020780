import { createAction } from 'redux-actions';

export const get_ignoredlinks_request = createAction('GET_IGNORED_LINKS_REQUEST');
export const get_ignoredlinks_success = createAction('GET_IGNORED_LINKS_SUCCESS');
export const get_ignoredlinks_failure = createAction('GET_IGNORED_LINKS_FAILURE');
export const get_ignored_link_success = createAction('GET_IGNORED_LINK_SUCCESS');


export const get_ignoredlinksnotices_request = createAction('GET_IGNORED_LINKS_NOTICES_REQUEST');
export const get_ignoredlinksnotices_success = createAction('GET_IGNORED_LINKS_NOTICES_SUCCESS');
export const get_ignoredlinksnotices_failure = createAction('GET_IGNORED_LINKS_NOTICES_FAILURE');