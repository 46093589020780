// Companies routes
import AddCompanyEventRoute from '../containers/Event/AddCompanyEvent';
import AmendmentEditRoute from '../containers/Amendment/AmendmentEdit';
import StepThreeRoute from '../containers/Amendment/StepThree';
import StepOneRoute from '../containers/Amendment/StepOne';
import StepTwoRoute from '../containers/Amendment/StepTwo';
import CompaniesRoute from "../containers/Companies";
import CompanyRoute from '../containers/Company';
import RenewalStepTwoRoute from '../containers/Renewal/StepTwo';
import RenewalStepThreeRoute from '../containers/Renewal/StepThree';
import RenewalStepOneRoute from '../containers/Renewal/StepOne';

export default [
	// Companies routes
  { component: RenewalStepThreeRoute, path: '/company/:company_id/renew/:order_id/processing', name: 'company-renewal-processing', isPrivateRoute: true },
  { component: RenewalStepTwoRoute, path: '/company/:company_id/renew/:order_id/check-your-order', name: 'company-renewal-step-2', isPrivateRoute: true},
  { component: RenewalStepOneRoute, path: '/company/:company_id/renew/', name: 'company-renewal-step-1', isPrivateRoute: true},

  { component: AddCompanyEventRoute, path: '/company/:company_id/createevent/', name: 'company-create-event', exact: true, isPrivateRoute: true },
  { component: StepTwoRoute, path: '/company/:company_id/amendment/:order_id/check-your-order', name: 'amendment-step-2', isPrivateRoute: true},
  { component: AmendmentEditRoute, path: '/company/:company_id/amendment/:order_id/edit', name: 'amendment-edit-order', isPrivateRoute: true},
  { component: StepThreeRoute, path: '/company/:company_id/amendment/:order_id/step-three', name: 'amendment-step-3', isPrivateRoute: true},
  { component: StepOneRoute, path: '/company/:company_id/amendment/submit-changes', name: 'amendment-step-1', isPrivateRoute: true },
  { component: CompanyRoute, path: '/company/:company_id', name: 'company-view', isPrivateRoute: true },
  { component: CompaniesRoute, path: '/companies', name: 'companies', isPrivateRoute: true },
];
