import { createAction } from 'redux-actions';

export const get_company_history_request = createAction('GET_COMPANIES_HISTORY_REQUEST');
export const get_company_history_success = createAction('GET_COMPANIES_HISTORY_SUCCESS');
export const get_company_history_failure = createAction('GET_COMPANIES_HISTORY_FAILURE');

export const get_histories_request = createAction('GET_HISTORIES_REQUEST');
export const get_histories_success = createAction('GET_HISTORIES_SUCCESS');
export const get_histories_failure = createAction('GET_HISTORIES_FAILURE');

export const get_snapshot_request = createAction('GET_SNAPSHOT_REQUEST');
export const get_snapshot_success = createAction('GET_SNAPSHOT_SUCCESS');
export const get_snapshot_failure = createAction('GET_SNAPSHOT_FAILURE');

export const get_event_representatives_request = createAction('GET_EVENT_REPRESENTATIVES_REQUEST');
export const get_event_representatives_success = createAction('GET_EVENT_REPRESENTATIVES_SUCCESS');
export const get_event_representatives_failure = createAction('GET_EVENT_REPRESENTATIVES_FAILURE');

export const get_event_notes_request = createAction('GET_EVENT_NOTES_REQUEST');
export const get_event_notes_success = createAction('GET_EVENT_NOTES_SUCCESS');
export const get_event_notes_failure = createAction('GET_EVENT_NOTES_FAILURE');

export const add_event_note_request = createAction('ADD_EVENT_NOTE_REQUEST');
export const add_event_note_success = createAction('ADD_EVENT_NOTE_SUCCESS');
export const add_event_note_failure = createAction('ADD_EVENT_NOTE_FAILURE');

export const clear_history = createAction('CLEAR_HISTORY');

export const add_snapshot = createAction('ADD_SNAPSHOT');
