import * as NoticesActions from '../actions/notices';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Notices {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Add notice links
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  addNoticeUrl({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(NoticesActions.add_notice_link_request({}));
      return axios
        .post(`${API_URL}/links-add/`, data, autorization)
        .then((response) => {
          dispatch(NoticesActions.add_notice_link_success({ link: response.data }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(NoticesActions.add_notice_link_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get notice by id
   * @param resolve
   * @param reject
   * @param headers
   * @param notice_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getNotice({
    resolve = noop,
    reject = noop,
    headers = {},
    notice_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(NoticesActions.get_notice_request({ notice_id, notice_not_found: false }));
      }
      return axios
        .get(`${API_URL}/notices/${notice_id}/`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(NoticesActions.get_notice_success({
              notice: response.data,
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(NoticesActions.get_notice_failure({
              notice_not_found: true,
            }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }


  updateNoticeSender({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    notice_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(NoticesActions.update_notice_request({ notice_id, data }));
      return axios
        .put(`${API_URL}/notices/${notice_id}/sender/`, data, autorization)
        .then((response) => {
          dispatch(NoticesActions.update_notice_success({
            notice_id,
            data,
            notice: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(NoticesActions.update_notice_failure({ notice_id, data }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Update notice
   * @param {*} param0
   * @returns
   */
  updateNotice({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    notice_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(NoticesActions.update_notice_request({ notice_id, data }));
      return axios
        .put(`${API_URL}/notices/${notice_id}/`, data, autorization)
        .then((response) => {
          dispatch(NoticesActions.update_notice_success({
            notice_id,
            data,
            notice: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(NoticesActions.update_notice_failure({ notice_id, data }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Update notice
   * @param {*} param0
   * @returns
   */
  sendNoticeEmail({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    notice_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(NoticesActions.send_notice_email_request({ notice_id, data }));

      return axios
        .post(`${API_URL}/notices/${notice_id}/send/`, data, autorization)
        .then((response) => {
          dispatch(NoticesActions.send_notice_email_success({
            notice_id,
            data,
            notice: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(NoticesActions.send_notice_email_failure({ notice_id, data }));
          Logger.logResponseError(error);
          reject(error.response);
        });
    };
  }

  /**
   * Upload
   * @param resolve
   * @param reject
   * @param headers
   * @returns {function(*, *, {axios: *})}
   */
  uploadNoticesFiles({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = {
      headers: { Authorization: `JWT ${headers.token}` },
      'Content-Type': 'multipart/form-data',
    };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/upload/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Add notice attachments
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param notice_id
   * @returns {function(*, *, {axios: *})}
   */
  addNoticeMultipleAttachments({
    resolve = noop,
    reject = noop,
    headers = {},
    notice_id,
    data = {},
  }) {
    const autorization = {
      headers: { Authorization: `JWT ${headers.token}` },
      'Content-Type': 'multipart/form-data',
    };

    return (dispatch, getState, { axios }) => {
      return axios.post(`${API_URL}/notices/${notice_id}/attachment/`, data, autorization).then((response) => {
        resolve(response);
      }).catch((error) => {
        Logger.logResponseError(error);
        reject(error);
      });
    };
  }

  /**
   * Get notice history
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param object_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getNoticeHistory({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    object_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    const query = `?limit=${per_page}&offset=${(page - 1) * per_page}&object_id=${object_id}&content_type__model=notice`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(NoticesActions.get_notice_history_request({ page, per_page }));
      }
      return axios
        .get(`${API_URL}/history/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(NoticesActions.get_notice_history_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(NoticesActions.get_notice_history_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Add
   * @param resolve
   * @param reject
   * @param headers
   * @returns {function(*, *, {axios: *})}
   */
  addNotices({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/notices/create/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get notice statuses
   * @param {*} param0
   */
  getNoticesStatuses({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 50,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/notices/statuses/?limit=${per_page}&offset=${(page - 1) * per_page}`, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Add notice contact
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  addNoticeContact({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/notices/contact/create/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get counties
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @returns {function(*, *, {axios: *})}
   */
  getCountries({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/countries/?limit=${per_page}&offset=${(page - 1) * per_page}`, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get notice contacts
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @returns {function(*, *, {axios: *})}
   */
  getNoticeContacts({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search = null,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      let params = `?limit=${per_page}&offset=${(page - 1) * per_page}`;
      if (search) {
        params += `&search=${search}`;
      }
      return axios
        .get(`${API_URL}/notices/contacts/${params}`, autorization)
        .then((response) => {
          response.data = {
            results: response.data.results,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            },
          };

          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get notice sources
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @returns {function(*, *, {axios: *})}
   */
  getNoticeSources({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/notices/sources/?limit=${per_page}&offset=${(page - 1) * per_page}`, autorization)
        .then((response) => {
          response.data = {
            results: response.data.results,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            }
          };

          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get carriers
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @returns {function(*, *, {axios: *})}
   */
  getCarriers({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/notices/carriers/?limit=${per_page}&offset=${(page - 1) * per_page}`, autorization)
        .then((response) => {
          response.data = {
            results: response.data.results,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            }
          };

          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get Notices
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param params
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getNotices({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    params = {},
    dispatchAction = true,
    isAll = false,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if((('show_sent' in params) && params['show_sent'])) {
      query+= '&show_sent=1';
    }

    for (let prop in params) {
      if (params[prop] && prop != 'show_sent' && params[prop] != 'hide_sent') {
        query += `&${prop}=${params[prop]}`;
      }
    }

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(NoticesActions.get_notices_request({ params, page, per_page }));
      }
      return axios
        .get(isAll !== '0' ? `${API_URL}/notices/${query}`: `${API_URL}/notices/without-sent/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(NoticesActions.get_notices_success({
              params, page, per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),// isAll !== 0 ? 1 : Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(NoticesActions.get_notices_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
          console.log(error);
        });
    };
  }



  /**
   * Get company notices
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param load_more
   * @param not_dispatch
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyNotices({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    load_more = false,
    not_dispatch = false,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (!not_dispatch) {
        dispatch(NoticesActions.get_company_notices_request({ load_more, company_notices_id: company_id }));
      }
      return axios
        .get(`${API_URL}/companies/${company_id}/notices/`, autorization)
        .then((response) => {
          if (!not_dispatch) {
            dispatch(
              NoticesActions.get_company_notices_success({
                company_notices_loading: false,
                company_notices_loaded: true,
                company_notices_id: company_id,
                company_notices: [{
                  id: 1,
                  from: 'Alfred Morris',
                  domain: 'shapiro.com',
                  source: 'Mail',
                },
                  {
                    id: 2,
                    from: 'James McLane',
                    domain: 'shapiro.com',
                    source: 'Fax',
                  },
                  {
                    id: 3,
                    from: 'Ilie Oxani',
                    domain: 'shapiro.com',
                    source: 'Mail',
                  }],
              }),
            );
          }
          resolve(response, dispatch);
        })
        .catch((error) => {
          if (!not_dispatch) {
            dispatch(
              NoticesActions.get_company_notices_failure({
                company_notices_loaded: true,
                company_notices_loading: false,
                company_notices_id: company_id,
                company_notices: []
              }),
            );
          }

          Logger.logResponseError(error);
          reject(error, dispatch);
        });
    };
  }

   /**
   * Delete order
   * @param {*} param0
   */
  deleteNotice({
    resolve = noop,
    reject = noop,
    headers = {},
    notice_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .delete(`${API_URL}/notices/${notice_id}/`, autorization)
        .then((response) => {
          dispatch(NoticesActions.delete_notice_success({
            notice_id,
          }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }


  checkLatest({
    resolve = noop,
    reject = noop,
    headers = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/notices/check-last/`, autorization)
        .then((response) => {
          resolve(response.status);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error.response.status);
        });
    };
  }
}

export default new Notices();
