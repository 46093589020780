import ModalNewCompany from '../../components/Modals/ModalNewCompany/ModalNewCompany';
import {getLandingUrl, getLandingHost, getDomainName} from '../../utils/utils';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import React, { PureComponent } from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Auth } from '../../services';
import queryParse from 'query-string';
import { compose } from 'redux';
import Modal from 'react-modal';

Modal.setAppElement('#app');

class Sidebar extends PureComponent {
  /**
   * Clss constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    const queries = queryParse.parse(props.location.search);
    const isGetStarted = queries.get_started_now === 'true';

    this.logout = this.logout.bind(this);
    this.toggleOpenModal = this.toggleOpenModal.bind(this);

    this.state = {
      addCompanyModalVisible: props.user && isGetStarted ? true : false,
      getLandingUrl: '',
      queries,
    };
  }

  get isVisibleSidebar() {
    const {location} = this.props;
    let isVisible = true;

    if (location.pathname && location.pathname.indexOf('/representative/confirm') > -1) {
      isVisible = false;
    }

    return isVisible;
  }

  componentDidMount() {
    const {history,location} = this.props;
    const {queries} = this.state;

    if (queries.get_started_now === 'true') {
      history.replace(`${location.pathname}`);
    }

    this.setState({
      getLandingUrl: getLandingUrl(),
    });
  }

  /**
   * Toggle Open Company Modal
   * @param action
   * @param event
   */
  toggleOpenModal(action) {
    this.setState({
      addCompanyModalVisible: action
    });
  }

  logout() {
    this.props.cookies.remove('token', { path: "/" });
    this.props.cookies.remove('refresh', { path: "/" });
    this.props.cookies.remove(`auth_${ getLandingHost() }`, { 
      domain: `${getDomainName()}`, 
      path: '/', 
    });
    this.props.cookies.remove('closed_draft_orders', { path: '/'});
    this.props.logout();
    this.props.history.replace('/login');
  }

  render() {
    const { user = {}, location } = this.props;
    const { addCompanyModalVisible } = this.state;

    if (!this.isVisibleSidebar) {
      return null;
    }

    return (
      <aside>
        <Modal
          onRequestClose={ this.toggleOpenModal.bind(null, false) }
          overlayClassName="react-modal-overlay add-new-company"
          isOpen={ addCompanyModalVisible }
          shouldCloseOnOverlayClick={ false }
          className="react-modal modal-new-company"
          closeTimeoutMS={ 450 }>
          <ModalNewCompany
            onRequestClose={ this.toggleOpenModal.bind(null, false) }
          />
        </Modal>
        <div className="top-aside">
          <a href={this.state.getLandingUrl} className="logo">
            <img src="/img/logo.svg" alt="" />
          </a>
          <nav className="nav">
            <NavLink
              to="/"
              className="nav_item"
              activeClassName={'active'}
              exact={true}
              isActive={() => {
                let isActive =
                  location.pathname === '/' ||
                  location.pathname.indexOf('/company') > -1 ||
                  location.pathname.indexOf('/history') > -1;
                return isActive;
              }}
            >
              <i className="icons home-outline" />
              Company
            </NavLink>
            <NavLink
              to="/notices"
              className="nav_item"
              activeClassName={'active'}
            >
              <i className="icons notices-outline" />Notices
            </NavLink>
            <NavLink
              to="/domains"
              className="nav_item"
              activeClassName={'active'}
            >
              <i className="icons domains-outline" />Domains
            </NavLink>
            <NavLink
                to="/orders"
                className="nav_item"
                activeClassName={'active'}
              >
                <i className="icons orders-outline" />Orders
              </NavLink>
              <NavLink
                to="/senders"
                className="nav_item"
                activeClassName={'active'}
              >
                <i className="icons sender-icon" />Senders
              </NavLink>
            {user.is_superuser && (
              <nav className="nav">
                <NavLink
                  className="nav_item admin-link first-admin-link"
                  activeClassName={'active'}
                  to="/companies"
                >
                  <i className="icons home-outline" />Companies
                </NavLink>
                <NavLink
                  className="nav_item admin-link"
                  activeClassName={'active'}
                  to="/users"
                  isActive={() => {
                    let isActive =
                      location.pathname.indexOf('/users') > -1 ||
                      location.pathname.indexOf('/profile') > -1;
                    return isActive;
                  }}
                >
                  <i className="icons users-outline" />Users
                </NavLink>
                <NavLink
                  className="nav_item admin-link"
                  activeClassName={'active'}
                  to="/filters"
                >
                  <i className='icons rule'></i>
                  {/* <i className="icons orders-outline" /> */}
                  Rules
                </NavLink>
                <NavLink
                  className="nav_item admin-link"
                  activeClassName={'active'}
                  to="/ignored-links"
                >
                  <i className='icons public_off'></i>
                  {/* <i className="icons orders-outline" /> */}
                  Ignored Links
                </NavLink>
              </nav>
            )}
          </nav>
          <button type="button" className="btn btn-white" onClick={ this.toggleOpenModal.bind(null, true) }>
            Add New Company
          </button>
        </div>
        <div className="logged-in-block">
          <Link className="logged-in-info" to={{ pathname: '/account' }}>
            <div className="logged-in-img">
              <Avatar type="normal" image={ user.info.image ? user.info.image.file : '' } user={ user } />
            </div>
            <div className="logged-in-name">
              { (user.first_name || user.last_name) &&
                <span>
                  <b>{ user.first_name }</b> <br />
                  <b>{ user.last_name }</b>
                </span>
              }
              { !user.first_name && !user.last_name &&
                <b className="not-exist-name">{ user.email }</b>
              }
            </div>
          </Link>
          <a href="javascript:;"
            className="btn-icon open-btn"
            onClick={this.logout}
          >
            <i className="material-icons">exit_to_app</i>
          </a>
        </div>
      </aside>
    );
  }
}

export default compose(
  withCookies,
  withRouter,
  connect(
    state => {
      return {
        user: state.account.user,
      };
    },
    {
      logout: Auth.logout,
    },
  ),
)(Sidebar);
