import { createAction } from 'redux-actions';

export const get_company_domains_request = createAction('GET_COMPANIES_DOMAINS_REQUEST');
export const get_company_domains_success = createAction('GET_COMPANIES_DOMAINS_SUCCESS');
export const get_company_domains_failure = createAction('GET_COMPANIES_DOMAINS_FAILURE');

export const get_amendment_company_domains_request = createAction('GET_AMENDMENT_COMPANIES_DOMAINS_REQUEST');
export const get_amendment_company_domains_success = createAction('GET_AMENDMENT_COMPANIES_DOMAINS_SUCCESS');
export const get_amendment_company_domains_failure = createAction('GET_AMENDMENT_COMPANIES_DOMAINS_FAILURE');

export const get_domains_request = createAction('GET_DOMAINS_REQUEST');
export const get_domains_success = createAction('GET_DOMAINS_SUCCESS');
export const get_domains_failure = createAction('GET_DOMAINS_FAILURE');

export const get_domain_request = createAction('GET_DOMAIN_REQUEST');
export const get_domain_success = createAction('GET_DOMAIN_SUCCESS');
export const get_domain_failure = createAction('GET_DOMAIN_FAILURE');

export const get_domain_orders_request = createAction('GET_DOMAIN_ORDERS_REQUEST');
export const get_domain_orders_success = createAction('GET_DOMAIN_ORDERS_SUCCESS');
export const get_domain_orders_failure = createAction('GET_DOMAIN_ORDERS_FAILURE');

export const get_domain_notices_request = createAction('GET_DOMAIN_NOTICES_REQUEST');
export const get_domain_notices_success = createAction('GET_DOMAIN_NOTICES_SUCCESS');
export const get_domain_notices_failure = createAction('GET_DOMAIN_NOTICES_FAILURE');

export const get_domain_histories_request = createAction('GET_DOMAIN_HISTORIES_REQUEST');
export const get_domain_histories_success = createAction('GET_DOMAIN_HISTORIES_SUCCESS');
export const get_domain_histories_failure = createAction('GET_DOMAIN_HISTORIES_FAILURE');

export const get_create_events_domains_request = createAction('GET_CREATE_EVENTS_DOMAINS_REQUEST');
export const get_create_events_domains_success = createAction('GET_CREATE_EVENTS_DOMAINS_SUCCESS');
export const get_create_events_domains_failure = createAction('GET_CREATE_EVENTS_DOMAINS_FAILURE');

export const update_domain_request = createAction('UPDATE_DOMAIN_REQUEST');
export const update_domain_success = createAction('UPDATE_DOMAIN_SUCCESS');
export const update_domain_failure = createAction('UPDATE_DOMAIN_FAILURE');

export const add_domain_from_list = createAction('ADD_DOMAIN_FROM_LIST');

export const download_domains = createAction('DOWNLOAD_DOMAINS');
