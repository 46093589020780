import EE from '../utils/EventEmitter';
import autoBind from 'auto-bind';

class Logger {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Log response error
   * @param error
   * @param customError
   */
  logResponseError(error, customError) {
    try {
      const response = error.response;

      if (response) {
        if (response.status === 401 || response.status === 403) {
          EE.emit('dmca:unauthorized', response, customError);
        } else {
          EE.emit('dmca:error', response, customError);
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }
}

export default new Logger();
