import * as SendersActions from './../../actions/senders';
import * as AccountActions from './../../actions/account';
import { handleActions } from 'redux-actions';

const initialState = {
  // Senders
  senders_pagination: {},
  senders_loading: false,
  senders_loaded: false,
  senders: [],

  // Sender data
  sender_loading: false,
  sender: {},

  // Sender notices
  sender_notices_pagination: {},
  sender_notices_loading: false,
  sender_notices_loaded: false,
  sender_notices: [],
};

// Reducer
const reducer = handleActions(
  {
    // Get senders list
    [SendersActions.get_sender_notices_request]: (state) => ({ ...state,
      sender_notices_loading: true,
    }),
    [SendersActions.get_sender_notices_success]: (state, { payload: { results, pagination } }) => {
      return { ...state,
        sender_notices_pagination: pagination,
        sender_notices_loading: false,
        sender_notices_loaded: true,
        sender_notices: results,
      };
    },
    [SendersActions.get_sender_notices_failure]: (state) => ({ ...state,
      sender_notices_loading: false,
    }),

    // Delete sender
    [SendersActions.delete_sender_success]: (state, { payload: { sender_id } }) => {
      return { ...state,
        senders: state.senders.filter((sender) => sender.id !== sender_id),
      };
    },

    // Update sender
    [SendersActions.update_sender_success]: (state, { payload: { sender } }) => {
      const payload = {};
      
      if (state.sender.id === sender.id) {
        payload.sender = sender;
      }

      payload.senders = state.senders.map((item) => {
        if (item.id === sender.id) {
          item = {
            ...item,
            ...sender,
          };
        }

        return item;
      })

      return { ...state,
        ...payload,
      };
    },

    // Get senders list
    [SendersActions.get_senders_request]: (state) => ({ ...state,
      senders_loading: true,
    }),
    [SendersActions.get_senders_success]: (state, { payload: { results, pagination } }) => {

      return { ...state,
        senders_pagination: pagination,
        senders_loading: false,
        senders: results,
      };
    },
    [SendersActions.get_senders_failure]: (state) => ({ ...state,
      senders_loading: false,
    }),

    // Get sender
    [SendersActions.get_sender_request]: (state) => ({
      ...state,
      sender_loading: true,
    }),
    [SendersActions.get_sender_success]: (state, { payload: { sender = {} } }) => {
      return {
        ...state,
        sender_loading: false,
        sender,
      };
    },
    [SendersActions.get_sender_failure]: (state) => ({
      ...state,
      sender_loading: false,
    }),
    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default reducer;
