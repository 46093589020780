import PropTypes from 'prop-types';
import React from 'react';

const Avatar = ({ image, user, type }) => {
  return (
    <div className={`avatar-row ${ type }`}>
      { image ? (
        <img src={ image } alt="user-image"/>
      ) : (
        <div className="avatar-placeholder">
          {user && user.first_name && user.last_name && <span>{ user.first_name.charAt(0) }{ user.last_name.charAt(0) }</span> }
          {user && user.first_name && !user.last_name && <span>{ user.first_name.charAt(0) }</span> }
          {user && !user.first_name && user.last_name && <span>{ user.last_name.charAt(0) }</span> }
          {user && !user.first_name && !user.last_name && <span className="email">@</span> }
        </div>
      )
      }
    </div>
  )
};

Avatar.propTypes = {
  image: PropTypes.string,
  type: PropTypes.oneOf(['small', 'medium', 'normal', 'big']),
  user: PropTypes.object
};

Avatar.defaultProps = {
  user: {}
};

export default Avatar;
