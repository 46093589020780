import * as ProfileActions from '../actions/profile';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class XeroCallbackService {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Get user login data
   * @param {*} param0
   */
  xeroCallback({
    resolve = noop,
    reject = noop,
    headers = {},
    params = null,
    user_id,
    per_page = 10,
    page = 1,
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(ProfileActions.get_profile_login_data_request({ user_id }));
      return axios
        .get(`${API_URL}/xero/accepted/${params}`, authorization)
        .then((response) => {
          window.location = '/';
        })
        .catch((error) => {
          dispatch(ProfileActions.get_profile_login_data_failure({ user_id }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   *
   * @param {*} param0
   * @returns
   */
  reconnect({
    resolve = noop,
    reject = noop,
    headers = {},
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (
      dispatch,
      getState,
      { axios },
    ) => {
      return axios
        .post(`${API_URL}/xero/reconnect/`, {}, authorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    };
  }

  // companies/(?P<pk>[0-9]+)/connect-xero/
  linkContactId({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    data,
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/companies/${company_id}/connect-xero/`, data, authorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    };
  }
}

export default new XeroCallbackService();
