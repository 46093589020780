// Account routes
import ProfileRoute from '../containers/Profile';
import UsersRoute from '../containers/Users';
import HomeRoute from '../containers/Home';

export default [
	// Account routes
  { component: ProfileRoute, path: '/profile/:profile_id', name: 'profile-view', isPrivateRoute: true },
  { component: ProfileRoute, path: '/account', name: 'account', exact: true, isPrivateRoute: true },
  { component: UsersRoute, path: '/users', name: 'users', isPrivateRoute: true },
  { component: HomeRoute, path: '/', name: 'Home', isPrivateRoute: true },
];
