import { createAction } from 'redux-actions';

export const get_user_companies_request = createAction('GET_USER_COMPANIES_REQUEST');
export const get_user_companies_success = createAction('GET_USER_COMPANIES_SUCCESS');
export const get_user_companies_failure = createAction('GET_USER_COMPANIES_FAILURE');

export const get_admin_companies_request = createAction('GET_ADMIN_COMPANIES_REQUEST');
export const get_admin_companies_success = createAction('GET_ADMIN_COMPANIES_SUCCESS');
export const get_admin_companies_failure = createAction('GET_ADMIN_COMPANIES_FAILURE');

export const get_company_request = createAction('GET_COMPANY_REQUEST');
export const get_company_success = createAction('GET_COMPANY_SUCCESS');
export const get_company_failure = createAction('GET_COMPANY_FAILURE');

export const get_amendment_company_request = createAction('GET_AMENDMENT_COMPANY_REQUEST');
export const get_amendment_company_success = createAction('GET_AMENDMENT_COMPANY_SUCCESS');
export const get_amendment_company_failure = createAction('GET_AMENDMENT_COMPANY_FAILURE');

export const select_amendment_default_data = createAction('SELECT_AMENDMENT_DEFAULT_DATA');

export const update_company_request = createAction('UPDATE_COMPANY_REQUEST');
export const update_company_success = createAction('UPDATE_COMPANY_SUCCESS');
export const update_company_failure = createAction('UPDATE_COMPANY_FAILURE');

export const get_company_orders_request = createAction('GET_COMPANY_ORDERS_REQUEST');
export const get_company_orders_success = createAction('GET_COMPANY_ORDERS_SUCCESS');
export const get_company_orders_failure = createAction('GET_COMPANY_ORDERS_FAILURE');

export const get_company_events_request = createAction('GET_COMPANY_EVENTS_REQUEST');
export const get_company_events_success = createAction('GET_COMPANY_EVENTS_SUCCESS');
export const get_company_events_failure = createAction('GET_COMPANY_EVENTS_FAILURE');

export const get_company_billing_info_request = createAction('GET_COMPANY_BILLING_INFO_REQUEST');
export const get_company_billing_info_success = createAction('GET_COMPANY_BILLING_INFO_SUCCESS');
export const get_company_billing_info_failure = createAction('GET_COMPANY_BILLING_INFO_FAILURE');

export const update_company_billing_info_request = createAction('UPDATE_COMPANY_BILLING_INFO_REQUEST');
export const update_company_billing_info_success = createAction('UPDATE_COMPANY_BILLING_INFO_SUCCESS');
export const update_company_billing_info_failure = createAction('UPDATE_COMPANY_BILLING_INFO_FAILURE');

export const add_company_from_list = createAction('ADD_COMPANY_FROM_LIST');

export const get_company_invoices_request = createAction('GET_COMPANY_INVOICES_REQUEST');
export const get_company_invoices_success = createAction('GET_COMPANY_INVOICES_SUCCESS');
export const get_company_invoices_failure = createAction('GET_COMPANY_INVOICES_FAILURE');


export const get_company_notes_request = createAction('GET_COMPANY_NOTES_REQUEST');
export const get_company_notes_success = createAction('GET_COMPANY_NOTES_SUCCESS');
export const get_company_notes_failure = createAction('GET_COMPANY_NOTES_FAILURE');

export const add_company_note_request = createAction('ADD_EVENT_NOTE_REQUEST');
export const add_company_note_success = createAction('ADD_EVENT_NOTE_SUCCESS');
export const add_company_note_failure = createAction('ADD_EVENT_NOTE_FAILURE');
