import axios from 'axios';
import { getCookie, deleteCookie } from '../utils/utils';
import { getApiUrl } from '../utils/utils';
import jwt from 'jwt-decode';


const API_URL = getApiUrl();
export const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(async (config) => {
  let token = localStorage.getItem('token');
  const refresh = getCookie('refresh');

  if (refresh || token) {
    let decoded_token = '';
    let decoded_refresh = '';

    if(token) {
      decoded_token = jwt(token);
    }

    if(refresh) {
      decoded_refresh = jwt(refresh);
    }

    if( decoded_token && Date.now() > decoded_token.exp * 1000) {
        const request = await fetch(`${API_URL}/api-token-refresh/`, {
          method: 'POST',
          body: JSON.stringify({
            refresh: refresh
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        }).catch((err) => {
          localStorage.removeItem('token');
          deleteCookie('refresh');
        });

        if(request.status === 401 || request.status === 400) {
          localStorage.removeItem('token');
          deleteCookie('refresh');
        }

        const data = await request.json();
        token = data.access;
    }

    if(refresh && !decoded_token) {
      deleteCookie('refresh');
    }

    if(token) {
      if( (decoded_token && Date.now() > decoded_token.exp * 1000) && !refresh ) {
        localStorage.removeItem('token');
      }
      config.headers.Authorization = `JWT ${token}`;
      localStorage.setItem('token', token);
    }
  }

  return config;
}, (error) => {
  Promise.reject(error);
});

const fitler_routes = ['/api-auth/']

axiosApiInstance.interceptors.response.use( async (response) => {
  return response;
}, async (error) => {
  let token = localStorage.getItem('token');
  let inFitler = false;

  fitler_routes.forEach((val, index) => {
    if(error.response.request.responseURL.includes(val)) {
      inFitler = true;
    }
  });

  if (
    error.response?.status && error.response.status === 401 && !token && !inFitler
  ) {
    window.location.replace('/login?back_url=' + window.location.href.replace(window.location.origin, ''));
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
});