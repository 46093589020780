import { createAction } from 'redux-actions';

export const get_users_request = createAction('GET_USERS_REQUEST');
export const get_users_success = createAction('GET_USERS_SUCCESS');
export const get_users_failure = createAction('GET_USERS_FAILURE');

export const delete_user_request = createAction('DELETE_USER_REQUEST');
export const delete_user_success = createAction('DELETE_USER_SUCCESS');
export const delete_user_failure = createAction('DELETE_USER_FAILURE');

export const user_statuses_request = createAction('USER_STATUSES_REQUEST');
export const user_statuses_success = createAction('USER_STATUSES_SUCCESS');
export const user_statuses_failure = createAction('USER_STATUSES_FAILURE');
