import * as HistoriesActions from './../../actions/history';
import * as NoticesActions from './../../actions/notices';
import * as AccountActions from './../../actions/account';
import { handleActions } from 'redux-actions';

const initialState = {
  notice_not_found: false,
  notices_loading: false,
  notices_loaded: false,
  notices_pagination: {},
  notices: [],

  // Get notice history
  notice_history_loading: false,
  notice_history_loaded: false,
  notice_history_pagination: {},
  notice_history: [],

  // Notice info
  notice: {
    domains: []
  },
  notice_loading: false,
  notice_loaded: false,

  // Notice notes
  notice_create_loading: false,
  notice_notes_pagination: {},
  notice_notes_loading: false,
  notice_notes_loaded: true,
  notice_notes: [],

  // Notice links
  notice_links_pagination: {},
  notice_links_loading: false,
  notice_links_loaded: true,
  notice_links: [],
};

// Reducer
const reducer = handleActions(
  {
    // Add notice link
    [NoticesActions.add_notice_link_request]: (state) => {
      return {
        ...state,
      };
    },
    [NoticesActions.add_notice_link_success]: (state, { payload: { link={}} }) => {
      const pagination = state.notice_links_pagination;
      const notice_links = state.notice_links;
      const notice = state.notice;

      notice.links = notice.links.concat({
        ...link,
        link_value: link.value,
        name: link.name,
      });

      return { ...state,
        notice,
        notice_links: notice_links.concat([link]),
        notice_links_pagination: {
          ...pagination,
          max_page: Math.ceil(pagination.count + 1/pagination.per_page),
          count: pagination.count + 1,
        }
      };
    },
    [NoticesActions.add_notice_link_failure]: (state) => {
      return {
        ...state,
      };
    },

    // Add notice notes
    [HistoriesActions.add_event_note_request]: (state, { payload: { type } }) => {
      return type === 'notice' ? { ...state,
        notice_create_loading: true,
      } : {
        ...state,
      };
    },
    [HistoriesActions.add_event_note_success]: (state, { payload: { note={}, type } }) => {
      const pagination = state.notice_notes_pagination;
      const notice_notes = state.notice_notes;

      return type === 'notice' ? { ...state,
        notice_notes: notice_notes.concat([note]),
        notice_notes_pagination: {
          ...pagination,
          max_page: Math.ceil(pagination.count + 1/pagination.per_page),
          count: pagination.count + 1,
        },
        notice_create_loading: false,
      } : {
        ...state,
      };
    },
    [HistoriesActions.add_event_note_failure]: (state, { payload: { type } }) => {
      return type === 'notice' ? { ...state,
        notice_create_loading: false,
      } : {
        ...state,
      };
    },

    // Get notice links
    [NoticesActions.get_notice_urls_request]: (state, { payload: {} }) => {
      return {
        notice_links_loading: true,
        ...state,
      };
    },
    [NoticesActions.get_notice_urls_success]: (state, { payload: { pagination, results=[] } }) => {
      return { ...state,
        notice_links_pagination: pagination,
        notice_links_loading: false,
        notice_links_loaded: true,
        notice_links: results,
      };
    },
    [NoticesActions.get_notice_urls_failure]: (state, { payload: { type } }) => {
      return {
        ...state,
        notice_links_loading: false,
      };
    },

    // Get event notes
    [HistoriesActions.get_event_notes_request]: (state, { payload: { type } }) => {
      return type === 'notice' ? { ...state,
        notice_notes_loading: true,
      } : {
        ...state,
      };
    },
    [HistoriesActions.get_event_notes_success]: (state, { payload: { pagination, results=[], type } }) => {
      return type === 'notice' ? { ...state,
        notice_notes_pagination: pagination,
        notice_notes_loading: false,
        notice_notes_loaded: true,
        notice_notes: results,
      } : {
        ...state,
      };
    },
    [HistoriesActions.get_event_notes_failure]: (state, { payload: { type } }) => {
      return type === 'notice' ? { ...state,
        notice_notes_loading: false,
      } : {
        ...state,
      };
    },

    // Get domain histories
    [NoticesActions.get_notice_history_request]: (state, { payload }) => {
      return { ...state,
        notice_history_loading: true,
        ...payload
      };
    },
    [NoticesActions.get_notice_history_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        notice_history_pagination: pagination,
        notice_history_loading: false,
        notice_history_loaded: true,
        notice_history: results
      };
    },
    [NoticesActions.get_notice_history_failure]: (state, { payload }) => {
      return { ...state,
        notice_history_loading: false,
        ...payload
      };
    },

    // Get notice by id
    [NoticesActions.get_notice_request]: (state, { payload }) => {
      return { ...state,
        notice_not_found: false,
        notice_loading: true,
        ...payload 
      };
    },
    [NoticesActions.get_notice_success]: (state, { payload }) => {
      return { ...state,
        ...payload
      };
    },
    [NoticesActions.get_notice_failure]: (state, { payload }) => {
      return { ...state,
        notice_loading: false,
        ...payload
      };
    },

    // Add notice from list
    [NoticesActions.add_notice_from_list]: (state, { payload: { notice_id, notice = null } }) => {
      let elem = null;
      if (notice) {
        elem = notice;
        elem.domains = [];
      }

      state.notices.map((item) => {
        if (item.id === notice_id) {
          elem = item;
          elem.domains = [];
        }
        return item;
      });

      if (elem) {
        return { ...state,
          notice: elem,
          notice_not_found: false
        };
      } else {
        return state;
      }
    },

    // Get notices
    [NoticesActions.get_notices_request]: (state, { payload }) => {
      return { ...state,
        notices_loading: true
      };
    },
    [NoticesActions.get_notices_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        notices_pagination: pagination,
        notices_loading: false,
        notices_loaded: true,
        notices: results
      };
    },
    [NoticesActions.get_notices_failure]: (state, { payload }) => {
      return { ...state,
        notices_loading: false
      };
    },

    // Upadte notices
    [NoticesActions.update_notice_request]: (state, { payload }) => {
      return { ...state,
        notice_loading: true
      };
    },
    [NoticesActions.update_notice_success]: (state, { payload: { data, notice } }) => {
      return { ...state,
        notice_loading: false,
      };
    },
    [NoticesActions.update_notice_failure]: (state, { payload }) => {
      return { ...state,
        notice_loading: false
      };
    },

    [NoticesActions.update_notice_success]: (state, { payload }) => {
      return { ...state,
        ...payload
      };
    },

    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default reducer;
