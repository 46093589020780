import React, { PureComponent } from 'react';
import { noop } from '../../utils/utils';
import PropTypes from 'prop-types';

class PaymentMethodContainerItem extends PureComponent {
  /**
   * Class constructor
   * @param props
   */
  constructor(props) {
    super(props);
  }

  render() { 
    const { renderBody, title, imgUrl, loading, btnText } = this.props;
    return (
      <div className="payment-method-container-item">
        <span className="title">{title}</span>
        <div className="img-block">
          <img src={imgUrl} alt="img"/>
        </div>
        { renderBody && (typeof renderBody === 'function') && renderBody() }
        <button type="button" className={`btn btn-green btn-lg btn-db arrow-btn ${ loading ? 'loading' : '' }`} onClick={ this.props.onContinue }>
          Continue
          <i className="icons white-arrow"></i>
        </button>
      </div>
    );
  }
}

PaymentMethodContainerItem.propTypes = {
  onContinue: PropTypes.func,
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  btnText: PropTypes.string,
};

PaymentMethodContainerItem.defaultProps = {
  onContinue: noop,
  title: 'Title',
  imgUrl: 'Image',
  btnText: 'Continue'
};

export default PaymentMethodContainerItem;