const CryptoJS = require('crypto-js');

class CriptoJs {
  /**
   * Class constructor
   */
  constructor() {
    this.SECRET = '123';
  }

  /**
   * Encript data
   * @param data
   * @returns {*}
   */
  encript(data) {
    const hash = CryptoJS.Rabbit.encrypt(JSON.stringify(data), this.SECRET).toString();

    return hash;
  }

  /**
   * Decript data
   * @param hash
   */
  decrypt(hash) {
    const bytes = CryptoJS.Rabbit.decrypt(hash, this.SECRET);
    let data = {};
    try {
      data = bytes.toString(CryptoJS.enc.Utf8);
      data = JSON.parse(data);
    } catch (ex) {
      // console.log(ex);
    }

    return data;
  }
}

export default CriptoJs;
