
import OrderDetailsRoute from '../containers/Orders/OrderDetails';
import OrderPaymentRoute from '../containers/Orders/OrderPayment';
import OrderSubmitRoute from '../containers/Orders/OrderSubmit';
import OrderEditRoute from '../containers/Orders/OrderEdit';
import OrdersRoute from '../containers/Orders';

export default [
	// Orders routes
  { component: OrderDetailsRoute, path: '/orders/:order_id/detail', name: 'order-detail', exact: true, isPrivateRoute: true },
  { component: OrderPaymentRoute, path: '/orders/:order_id/payment', name: 'order-payment', exact: true, isPrivateRoute: true },
  { component: OrderSubmitRoute, path: '/orders/:order_id/submit', name: 'order-submit', exact: true, isPrivateRoute: true },
  { component: OrderEditRoute, path: '/orders/:order_id/edit', name: 'order-edit', exact: true, isPrivateRoute: true },
  { component: OrdersRoute, path: '/orders', name: 'orders', exact: true, isPrivateRoute: true }
];
