import { createAction } from 'redux-actions';

export const get_orders_request = createAction('GET_ORDERS_REQUEST');
export const get_orders_success = createAction('GET_ORDERS_SUCCESS');
export const get_orders_failure = createAction('GET_ORDERS_FAILURE');

export const post_orders_request = createAction('POST_ORDERS_REQUEST');
export const post_orders_success = createAction('POST_ORDERS_SUCCESS');
export const post_orders_failure = createAction('POST_ORDERS_FAILURE');

export const get_order_request = createAction('GET_ORDER_REQUEST');
export const get_order_success = createAction('GET_ORDER_SUCCESS');
export const get_order_failure = createAction('GET_ORDER_FAILURE');

export const get_draft_orders_request = createAction('GET_DRAFT_ORDERS_REQUEST');
export const get_draft_orders_success = createAction('GET_DRAFT_ORDERS_SUCCESS');
export const get_draft_orders_failure = createAction('GET_DRAFT_ORDERS_FAILURE');

export const update_draft_orders = createAction('UPDATE_DRAFT_ORDERS');

export const update_order_request = createAction('UPDATE_ORDER_REQUEST');
export const update_order_success = createAction('UPDATE_ORDER_SUCCESS');
export const update_order_failure = createAction('UPDATE_ORDER_FAILURE');

export const complete_order_request = createAction('COMPLETE_ORDER_REQUEST');
export const complete_order_success = createAction('COMPLETE_ORDER_SUCCESS');
export const complete_order_failure = createAction('COMPLETE_ORDER_FAILURE');

export const delete_order_request = createAction('DELETE_ORDER_REQUEST');
export const delete_order_success = createAction('DELETE_ORDER_SUCCESS');
export const delete_order_failure = createAction('DELETE_ORDER_FAILURE');


export const add_order_note_request = createAction('ADD_ORDER_NOTE_REQUEST');
export const add_order_note_success = createAction('ADD_ORDER_NOTE_SUCCESS');
export const add_order_note_failure = createAction('ADD_ORDER_NOTE_FAILURE');

export const add_order_link_request = createAction('ADD_ORDER_LINK_REQUEST');
export const add_order_link_success = createAction('ADD_ORDER_LINK_SUCCESS');
export const add_order_link_failure = createAction('ADD_ORDER_LINK_FAILURE');

export const remove_order_link_request = createAction('REMOVE_ORDER_LINK_REQUEST');
export const remove_order_link_success = createAction('REMOVE_ORDER_LINK_SUCCESS');
export const remove_order_link_failure = createAction('REMOVE_ORDER_LINK_FAILURE');

export const add_order = createAction('ADD_ORDER');

export const create_amendment_order_request = createAction('CREATE_AMENDMENT_ORDER_REQUEST');
export const create_amendment_order_success = createAction('CREATE_AMENDMENT_ORDER_SUCCESS');
export const create_amendment_order_failure = createAction('CREATE_AMENDMENT_ORDER_FAILURE');

export const add_files_to_order_request = createAction('ADD_FILES_TO_ORDER_REQUEST');
export const add_files_to_order_success = createAction('ADD_FILES_TO_ORDER_SUCCESS');
export const add_files_to_order_failure = createAction('ADD_FILES_TO_ORDER_FAILURE');

export const delete_order_file = createAction('DELETE_ORDER_FILE');
