import * as AccountActions from './../../actions/account';
import * as OrdersActions from './../../actions/orders';
import { handleActions } from 'redux-actions';

const initialState = {
  order_not_found: false,
  orders_loading: false,
  orders_loaded: false,
  orders_pagination: {},
  orders: [],

  // Draft orders
  orders_draft_loading: false,
  orders_draft_pagination: {},
  orders_draft_loaded: false,
  orders_draft: [],

  // Order info
  order_loading: false,
  order_loaded: false,
  order_id: null,
  order: {
    domains: [],
  },

  // Add order note
  order_note_loading: false,

  // Add order note
  order_link_loading: false
};

// Reducer
const reducer = handleActions(
  {
    // Remove order link
    [OrdersActions.remove_order_link_success]: (state, { payload: { link_id } }) => {
      let newOrder = state.order;

      if (newOrder && newOrder.links) {
        newOrder.links = newOrder.links.filter((link) => link.id !== link_id)
      }

      return { ...state,
        order: newOrder,
      };
    },

    // Update draft orders
    [OrdersActions.update_draft_orders]: (state, { payload: { type, order } }) => {
      let orders_draft_pagination = state.orders_draft_pagination;
      let orders_draft = state.orders_draft;
      let currentOrder = state.order;

      if (type === 'UPDATE') {
        orders_draft = orders_draft.map((item) => {
          if (item.id === order.id) {
            item.status = order.status;
          }
          return  item;
        });
      }

      if (currentOrder && currentOrder.id === order.id) {
        currentOrder.status = order.status;
      }

      return { ...state,
        orders_draft_pagination,
        order: currentOrder,
        orders_draft,
      };
    },
    
    // Delete order
    [OrdersActions.delete_order_success]: (state, { payload: { order_id } }) => {
      return { ...state,
        orders_draft: state.orders_draft.filter((order) => order.id !== order_id),
        orders: state.orders.filter((order) => order.id !== order_id),
      };
    },
    
    // Add order note
    [OrdersActions.get_draft_orders_request]: (state) => {
      return { ...state,
        orders_draft_loading: true,
      };
    },
    [OrdersActions.get_draft_orders_success]: (state, { payload: { results, pagination } }) => {
      return { ...state,
        orders_draft: pagination.page > 1 ? state.orders_draft.concat(results) : results,
        orders_draft_pagination: pagination,
        orders_draft_loading: false,
      };
    },
    [OrdersActions.get_draft_orders_failure]: (state, { payload }) => {
      return { ...state,
        orders_draft_loading: false,
      };
    },

    // Complete order
    [OrdersActions.complete_order_request]: (state, { payload }) => {
      return { ...state,
        complete_order_loading: true,
        ...payload
      };
    },
    [OrdersActions.complete_order_success]: (state, { payload: { order } }) => {
      let orders_draft = state.orders_draft;
      let orders = state.orders;

      orders = orders.map((item) => {
        if (item.id === order.id) {
          item.completed_by = order.completed_by;
          item.completed_date = order.completed_date;
          item.status = order.status;
        }

        return item;
      });

      orders_draft = orders_draft.map((item) => {
        if (item.id === order.id) {
          item.completed_date = order.completed_date;
          item.completed_by = order.completed_by;
          item.status = order.status;
        }
        
        return item;
      })

      return { ...state,
        complete_order_loading: false,
        orders_draft,
        order: {
          ...state.order,
          completed_by: order.completed_by,
          completed_date: order.completed_date,
          status: order.status
        },
        orders
      };
    },
    [OrdersActions.complete_order_failure]: (state, { payload }) => {
      return { ...state,
        complete_order_loading: false,
        ...payload
      };
    },

    // Create order
    [OrdersActions.post_orders_success]: (state, { payload: { order } }) => {
      let orders_draft = state.orders_draft;
      let orders = state.orders;

      orders_draft = [order].concat(orders_draft);
      orders = [order].concat(orders);

      return { ...state,
        orders_draft,
        orders,
      };
    },

    // Add order links
    [OrdersActions.add_order_link_request]: (state, { payload }) => {
      return { ...state,
        order_link_loading: true,
        ...payload
      };
    },
    [OrdersActions.add_order_link_success]: (state, { payload: { link } }) => {
      const order = state.order;

      order.links = order.links.concat([link]);
      
      return { ...state,
        order_link_loading: false,
        order
      };
    },
    [OrdersActions.add_order_link_failure]: (state, { payload }) => {
      return { ...state,
        order_link_loading: false,
        ...payload
      };
    },

    // Add order note
    [OrdersActions.add_order_note_request]: (state, { payload }) => {
      return { ...state,
        order_note_loading: true,
        ...payload
      };
    },
    [OrdersActions.add_order_note_success]: (state, { payload: { note } }) => {
      const order = state.order;

      order.notes = order.notes.concat([note]);
      
      return { ...state,
        order_note_loading: false,
        order
      };
    },
    [OrdersActions.add_order_note_failure]: (state, { payload }) => {
      return { ...state,
        order_note_loading: false,
        ...payload
      };
    },

    // Add order
    [OrdersActions.add_order]: (state, { payload: { order } }) => {
      return { ...state,
        order_not_found: false,
        order
      };
    },

    // Delete order file
    [OrdersActions.delete_order_file]: (state, { payload: { id } }) => {
      const order = state.order;

      order.files = order.files.filter((item) => item.file.id !== id);
      return { ...state,
        order
      };
    },

    // Add files to order
    [OrdersActions.add_files_to_order_request]: (state) => {
      return {
        ...state
      };
    },
    [OrdersActions.add_files_to_order_success]: (state, { payload: { files } }) => {
      const order = state.order;

      order.files = order.files.concat(files);
      return { ...state,
        order
      };
    },
    [OrdersActions.add_files_to_order_failure]: (state, { payload }) => {
      return { ...state,
        ...payload
      };
    },

    // Update order
    [OrdersActions.update_order_success]: (state, { payload: { order } }) => {
      let orders_draft = state.orders_draft;
      let orderItem = state.order;

      if (orderItem.id === order.id) {
        orderItem = {
          ...state.order,
          ...order,
        };
      }

      orders_draft = state.orders_draft.map((item) => {
        if (item.id === order.id) {
          item.status = order.status;
        }

        return item;
      });


      return { ...state,
        order: orderItem,
      };
    },

    // Get order info
    [OrdersActions.get_order_request]: (state, { payload }) => {
      return { ...state, order_loading: true };
    },
    [OrdersActions.get_order_success]: (state, { payload }) => {
      const order = state.order;
      
      if (
        order && payload.order && payload.order.status && order.status && 
        order.status.id === 8 && order.id === payload.order.id &&
        payload.order.status.id === 9
      ) {
        payload.order.status = order.status;
      }

      return { ...state,
        order_loading: false,
        order_loaded: true,
        ...payload
      };
    },
    [OrdersActions.get_order_failure]: (state, { payload }) => {
      return { ...state,
        order_loading: false,
        ...payload
      };
    },

    // Get Orders
    [OrdersActions.get_orders_request]: (state, { payload }) => {
      return { ...state,
        orders_loading: true
      };
    },
    [OrdersActions.get_orders_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        orders_pagination: pagination,
        orders_loading: false,
        orders_loaded: true,
        orders: results
      };
    },
    [OrdersActions.get_orders_failure]: (state, { payload }) => {
      return { ...state,
        orders_loading: false
      };
    },

    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default reducer;
