// Auth routes
import RepresentativeConfirmRoute from '../containers/Company/RepresentativeConfirm';
import ForgotEmailSendRoute from '../containers/Auth/ForgotEmailSend';
import RegisterConfirmRoute from '../containers/Auth/RegisterConfirm';
import ForgotPasswordRoute from '../containers/Auth/ForgotPassword';
import ResetPasswordRoute from '../containers/Auth/ResetPassword';
import RegisterRoute from '../containers/Auth/Register';
import LoginRoute from '../containers/Auth/Login';

export default [
	// Auth routes
  { component: RepresentativeConfirmRoute, path: '/representative/confirm/:token', name: 'representative-confirm', exact: true },
  { component: ForgotEmailSendRoute, path: '/forgotpassword/confirmation-send/:email', name: 'confirmation-send', exact: true },
  { component: ResetPasswordRoute, path: '/reset-password-confirm/:token', name: 'freelancer-register-active', exact: true },
  { component: RegisterConfirmRoute, path: '/register/confirm', name: 'register-confirm', exact: true },
  { component: ForgotPasswordRoute, path: '/forgotpassword', name: 'forgot-password', exact: true },
  { component: RegisterRoute, path: '/register', name: 'register', exact: true },
	{ component: LoginRoute, path: '/login', name: 'login', exact: true },
];
