import * as FiltersActions from '../actions/filters';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class FiltersService {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Get Filters
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param search
   * @param dispatchAction
   * @param params
   * @returns {function(*, *, {axios: *})}
   */
  getFilters({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search,
    dispatchAction = true,
    params = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if (search) { query += `&search=${search}` }

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(FiltersActions.get_filters_request({}));
      }

      return axios
        .get(`${API_URL}/filters/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(FiltersActions.get_filters_success({
              search, page, per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            //dispatch(DomainsActions.get_domains_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  updateFilter({
    resolve = noop,
    reject = noop,
    headers = {},
    filter_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

      return (dispatch, getState, { axios }) => {
        //dispatch(CompaniesActions.get_company_request({ }));
        return axios
          .patch(`${API_URL}/filters/${ filter_id }/`, data, autorization)
          .then((response) => {
            dispatch(FiltersActions.get_filter_success({
              filter: response.data
            }));
            resolve(response);
          })
          .catch((error) => {
            Logger.logResponseError(error);
            reject(error);
          });
    }
  }

  createFilter({
    resolve = noop,
    reject = noop,
    headers = {},
    filter_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      //dispatch(CompaniesActions.get_company_request({ }));
      return axios
        .post(`${API_URL}/filters/create/`, data, autorization)
        .then((response) => {
          dispatch(FiltersActions.get_filter_success({
            filter: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    }
  } 

  getFilter({
    resolve = noop,
    reject = noop,
    headers = {},
    filter_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      //dispatch(CompaniesActions.get_company_request({ }));
      return axios
        .get(`${API_URL}/filters/${ filter_id }/`, autorization)
        .then((response) => {
          dispatch(FiltersActions.get_filter_success({
            filter: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          // dispatch(
          //   CompaniesActions.get_company_failure({
          //     company_not_found: true,
          //     company_loaded: true,
          //     userCompanyPermissions: {
          //       roles: [],
          //     },
          //   }),
          // );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  deleteFilter({
    resolve = noop,
    reject = noop,
    headers = {},
    filter_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .delete(`${API_URL}/filters/${ filter_id }/`, autorization)
        .then((response) => {
          // dispatch(FiltersActions.get_filter_success({
          //   filter: response.data
          // }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }


  /**
   * Get conditions
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param search
   * @param dispatchAction
   * @param params
   * @returns {function(*, *, {axios: *})}
   */
   getConditions({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search,
    dispatchAction = true,
    params = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if (search) { query += `&search=${search}` }

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(FiltersActions.get_conditions_request({}));
      }

      return axios
        .get(`${API_URL}/conditions/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(FiltersActions.get_conditions_success({
              search, page, per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            //dispatch(DomainsActions.get_domains_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get conditions
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param search
   * @param dispatchAction
   * @param params
   * @returns {function(*, *, {axios: *})}
   */
   getFields({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search,
    dispatchAction = true,
    params = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if (search) { query += `&search=${search}` }

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(FiltersActions.get_fields_request({}));
      }

      return axios
        .get(`${API_URL}/fields/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(FiltersActions.get_fields_success({
              search, page, per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            //dispatch(DomainsActions.get_domains_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get conditions
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param search
   * @param dispatchAction
   * @param params
   * @returns {function(*, *, {axios: *})}
   */
   getFieldConditions({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search,
    dispatchAction = true,
    params = {},
    field_id='',
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if (search) { query += `&search=${search}` }

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    if(field_id !== null && field_id === 7)
      query += `&field_id=${ field_id }`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(FiltersActions.get_field_conditions_request({}));
      }

      return axios
        .get(`${API_URL}/fields/conditions/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(FiltersActions.get_field_conditions_success({
              search, page, per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            //dispatch(DomainsActions.get_domains_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get Filters
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param search
   * @param dispatchAction
   * @param params
   * @returns {function(*, *, {axios: *})}
   */
   getFilterActions({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search,
    dispatchAction = true,
    params = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if (search) { query += `&search=${search}` }

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(FiltersActions.get_filters_actions_request({}));
      }

      return axios
        .get(`${API_URL}/filters/actions/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(FiltersActions.get_filters_actions_success({
              search, page, per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            //dispatch(DomainsActions.get_domains_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}



export default new FiltersService();
