import { React } from 'react';

export default function PrivateRoute({ children }) {
    const token = localStorage.getItem("token") != undefined;

    if(!token) {
        return window.location.replace('/login');
    }

    return children;
}