import PaymentContainer from '../../PaymentContainer/PaymentContainer';
import GeneratorId from '../../GeneratorId/GeneratorId';
import AddNewCompanyForm from '../../../forms/AddNewCompanyForm';
import TableList from '../../TableList/TableList';
import { Companies, Orders, Domains } from '../../../services';
import AddDomainForm from '../../../forms/AddDomainForm';
import * as OrdersActions from '../../../actions/orders';
import { modalScrollToTop } from '../../../utils/utils';
import { withRouter } from 'react-router-dom';
import React, { PureComponent } from 'react';
import { SubmissionError } from 'redux-form';
import { noop, getHostnameFromString } from '../../../utils/utils';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { compose } from 'redux';
import { NativeTypes } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import { showError } from '../../../utils/utils';


class ModalNewCompany extends PureComponent {
  /**
   * Class constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.continueToPayment = this.continueToPayment.bind(this);
    this.onPaymentSuccess = this.onPaymentSuccess.bind(this);
    this.onPaymentFailure = this.onPaymentFailure.bind(this);
    this.onSubmitCompany = this.onSubmitCompany.bind(this);
    this.activateSteps = this.activateSteps.bind(this);
    this.deleteDomain = this.deleteDomain.bind(this);
    this.onAddDomain = this.onAddDomain.bind(this);
    this.onNextPress = this.onNextPress.bind(this);
    this.onBackPress = this.onBackPress.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.toasterActive = null;
    this.state = {
      add_domain_loading: false,
      add_order_loading: false,
      subscriptionType: 'year',
      domainBusyShow: false,
      domainExist: false,
      domainBusyData: {},
      activeState: 0,
      newDomains: [],
      domainName:'',
      company: {},
      order: {},
      uploadingFiles: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      modalScrollToTop();
    }, 0)
  }

  handleFileDrop(droppedFiles) {
    const { uploadDomains, cookies } = this.props;
    const headers = { token: localStorage.getItem('token') };
    const formData = new FormData();

    this.setState({ 
      uploadingFiles: true
    });
    
    droppedFiles.map((file) => {
      formData.append("files", file);
      return file;
    });

    uploadDomains({
      data: formData,
      headers,
      resolve: (response) => {
        let errors = response.data.errors;
        const domains = response.data.domains;
        
        if(errors.length) {
          let errorString = '';
          errors.forEach((element, index) => {
            if ('domain' in element || 'email' in element) {
              errorString+= 'Row #' + (index + 1) + ' '
              if ('domain' in element) {
                errorString += element.domain + ' ';
              }
              if ('email' in element) {
                errorString += element.email + ' ';
              }
              errorString += '\n';
            }
          });
          
          // if(errorString) {
          //   this.toasterActive = toast.error(errorString, {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          //     hideProgressBar: true,
          //     autoClose: 6000,
          //     allowHtml: true,
          //     enableHtml: true,
          //     onClose: () => {
          //       this.toasterActive = null;
          //     },
          //   });
          // }
        }

        this.setState({ uploadingFiles: false});

        if(domains.length) {
          domains.forEach(element => {
            this.onAddDomain(element, true);
          });
        }
      },
      reject: () => {
        this.setState({ uploadingFiles: false });
      }
    }); 
  }

  /**
   * Continue to payment
   */
  continueToPayment() {
    const { dispatch } = this.props;
    const { order } = this.state;

    dispatch(OrdersActions.add_order({ order }));
    this.activateSteps(3);
  }

  /**
   * Delete domain
   * @param domain
   */
  deleteDomain(domain) {
    let { newDomains } = this.state;

    newDomains = newDomains.filter((item) => item.name !== domain.name);
    this.setState({
      newDomains,
    });
  }

  /**
   * Add domains
   * @param values
   */
  onAddDomain(values, upload=false) {
    const { cookies, availableDomain } = this.props;

    this.setState({
      add_domain_loading: true,
    });

    values.name = getHostnameFromString(values.name);

    if(upload === true) {
      let newDomains = this.state.newDomains;
      this.setState({
        newDomains: [{ name: values.name, email: values.email}].concat(newDomains),
        add_domain_loading: false,
        domainBusyShow: false,
        domainBusyData: {},
      });

      if (this.form && typeof this.form.reset === 'function') {
        this.form.reset();
      }
    } else {
      availableDomain({
        headers: {
          token: localStorage.getItem('token')
        },
        data: {
          name: values.name
        },
        resolve: (response) => {
          if (response.data.busy) {
            this.setState({
              add_domain_loading: false,
              domainBusyShow: true,
              domainBusyData: {
                ...values,
                company: response.data.company,
                domain: response.data.domain,
              }
            });
          } else {
            let newDomains = this.state.newDomains;
            
            const domainExist = newDomains.filter(x => x.name.toLowerCase() == values.name.toLowerCase()).length;

            if(domainExist) {
              this.setState({
                add_domain_loading: false,
                domainBusyShow: true,
                domainExist: true,
                domainBusyData: {
                  ...values,
                  company: response.data.company,
                  domain: response.data.domain,
                }
              });
            } else {
              this.setState({
                newDomains: [{ name: values.name, email: values.email}].concat(newDomains),
                add_domain_loading: false,
                domainBusyShow: false,
                domainBusyData: {},
              });
    
              if (this.form && typeof this.form.reset === 'function') {
                this.form.reset();
              }
            }
          }
        },
        reject: (error) => {
          this.setState({
            add_domain_loading: false,
          });
        }
      });
    }
  }

  /**
   * On company submit
   * @param values
   */
  onSubmitCompany(values) {
    const payload = { ...values, domains: [] };
    const { subscriptionType, order } = this.state;
    const { cookies, history } = this.props;
    const isEditOrder = order && order.id;

    if (isEditOrder) {
      payload.removed = [];
      payload.added = [];

      delete payload.domains;
      delete payload.status;
      delete payload.form;
      delete payload.id;
    } else {
      payload.plan_id = subscriptionType;
    }

    if (this.state.add_order_loading) {
      return;
    }

    this.setState({
      add_order_loading: true,
    });

    return new Promise((resolve, reject) => {

      this.props[isEditOrder ? 'updateOrder' : 'createOrder']({
        headers: {
          token: localStorage.getItem('token'),
        },
        order_id: isEditOrder ? order.id : null,
        data: payload,
        resolve: (response) => {
          this.setState({
            order: response.data.order,
            add_order_loading: false,
            company: values,
            activeState: 1,
          }, () => {
            if (isEditOrder) {
              this.props.getOrder({
                order_id: parseInt(order.id),
                headers: {
                  token: localStorage.getItem('token'),
                },
              });
            } else {
              history.push(`/orders/${this.state.order.id}/detail`);
            }

            resolve();
          });
        },
        reject: (err) => {
          const error = err ? err.response : err;
          const payload = {};

          showError('', {
            autoClose: 11000,
          });

          error.data.errors.map((item) => {
            const splitArray = item.split(':');
            let prop = splitArray[0];

            if (prop && prop.indexOf('zip') > -1) {
              payload['zip'] = splitArray[1];
            }

            return item;
          });
          reject(new SubmissionError(payload));
          this.setState({
            add_order_loading: false,
          });
        }
      });
    });
  }

  onBackPress() {
    this.setState({
      activeState: 0,
    });
  }

  /**
   * On next press
   */
  onNextPress(skip) {
    const { newDomains, order } = this.state;
    const { cookies } = this.props;

    if (this.state.add_order_loading) {
      return;
    }

    this.setState({
      add_order_loading: true,
    });

    this.props.updateOrder({
      
      order_id: order.id,
      data: {
        added: skip ? [] : newDomains,
        status: 9,
      },
      resolve: (res) => {
        this.setState({
          newDomains: skip ? [] : newDomains,
          add_order_loading: false,
          order: res.data.order,
          activeState: 2,
        });
      },
      reject: (error) => {
        this.setState({
          add_order_loading: false,
        });
      },
    });
  }

  /**
   * Activate step
   * @param index
   */
  activateSteps(index) {
    this.setState({
      activeState: index
    });
  }

  /**
   * On Payment success
   * @param {*} response
   */
  onPaymentSuccess(response) {
    const { history } = this.props;
    const { order } = this.state;

    history.push(`/orders/${ order.id }/detail`);
    this.props.onRequestClose();
  }

  clickChild() {

  }

  /**
   * On payment failure
   * @param {*} error
   */
  onPaymentFailure(error) {
    const { history } = this.props;
    const { order } = this.state;

    history.push(`/orders/${ order.id }/detail`);
    this.props.onRequestClose();
  }

  render() {
    const { activeState, newDomains, domainName, domainExist, domainBusyShow, order, subscriptionType, add_domain_loading, add_order_loading } = this.state;
    const { domainBusyData, uploadingFiles } = this.state;
    const { account } = this.props;
    const { FILE } = NativeTypes;

    return (
      <div className="modal-new-company-block-body">
        <div className="modal-new-company-header">
          <a className="logo">
            <img src="/img/logo.svg" alt="" />
          </a>
          <span className="close-new-company-modal" onClick={ this.props.onRequestClose }>
            <img src="/img/icons/close.svg" alt="close"/>
          </span>
        </div>
        <div className="modal-new-company-steps-block">
          <div className={`modal-new-company-step ${ activeState > 0 ? 'step-completed' : 'step-active' }`}>
            <div className="inside-step">
              <i className={ `icons ${ activeState === 0 ? 'company-details-hover' : 'company-details'}` } />
              <span>Name & Address</span>
            </div>
          </div>
          <div className={`modal-new-company-step ${ activeState === 1 ? 'step-active' : ''} ${ activeState > 1 ? 'step-completed' : ''}`}>
              <div className="inside-step">
                <i className={`icons  ${ activeState === 1 ? 'domains-filled' : 'domains-light-gray'}`} />
                <span>Add Domains</span>
            </div>
          </div>
          <div className={`modal-new-company-step ${ activeState  === 2 ? 'step-active' : ''} ${ activeState > 2 ? 'step-completed' : ''}`}>
              <div className="inside-step">
                <i className={`icons ${ activeState === 2 ? 'check-circle-black' : 'check-circle-gray'}`} />
                <span>Confirmation</span>
            </div>
          </div>
          <div className={`modal-new-company-step ${ activeState  > 2 ? 'step-active' : ''}`}>
              <div className="inside-step">
                <i className={`icons ${ activeState === 3 ? 'payment-circle-black' : 'payment-circle'}`} />
                <span>Payment</span>
            </div>
          </div>
        </div>
        <div className="add-new-company-form">
          { activeState === 0 &&
            <div>
              <Helmet
                htmlAttributes={{ class: '' }}
                title={`Name & Address`}
              />
              <AddNewCompanyForm
                initialValues={{
                  form: {},
                  status: null,
                  ...this.state.company,
                }}
                onSubmit={this.onSubmitCompany}
                loading={add_order_loading}
              />
            </div>
          }
          { activeState === 1 &&
            <div className="new-company-step-2">
              <Helmet
                htmlAttributes={{ class: '' }}
                title={`Add Domains`}
              />
              <div className="extra-item">
                <span className="title">Domains & Emails</span>
                <p>
                  Please provide domains that can have user generate content. This can include CDN domains or other service-related domains.
                   Don’t forget you can register as many domains as you want!
                </p>
                <p>
                  We will forward all notices associated with a domain name to the email specified for that domain name.
                  Feel free to provide one email for all domains you are registering. You can also provide a specific email for each domain.
                  This is useful when you have unrelated services, and you want to process the notices separately for each service.
                </p>
              </div>
              { domainBusyShow &&
                <div className="existing-domain-warning">
                  <div className="warning-info">
                    <i className="icons warning-white"></i>
                    { domainExist && domainBusyData.name && <span>
                        This domain is already added. Domain names are case insensitive.
                      </span>
                    }
                    { domainBusyData.domain && domainBusyData.domain.status.id === 22 && !domainExist &&
                      <span>
                        <a href={`https://${ domainBusyData.name }`} target="blank">{ domainBusyData.name }</a> is associated with another order. If you think this is a mistake, please contact us right away.
                      </span>
                    }
                    { domainBusyData.domain && domainBusyData.domain.status.id === 13 && !domainExist &&
                      <span>
                        <a href={`https://${ domainBusyData.name }`} target="blank">{ domainBusyData.name }</a> is associated with <a href={"/company/" + domainBusyData.company.id} target="blank">{ domainBusyData.company.name }</a>. If you think this is a mistake, please contact us right away.
                      </span>
                    }
                  </div>
                  <span className="close-existing-domain-warning" onClick={() => this.setState({ domainBusyShow: false })}>
                    <i className="icons close-regular"></i>
                  </span>
                </div>
              }
              <div className="form-add-domain-switch-block switch">
                <div className="new-company-add-domain-block switch-block-left add-domain-form">
                  <AddDomainForm
                    ref={ (node) => this.form = node }
                    loading={ add_domain_loading }
                    onSubmit={ this.onAddDomain }
                    showClose={ false }
                  />
                </div>
              </div> 
              <TableList
                className={`table ${ newDomains.length === 0 ? 'no-data' : '' } no-hover-rows`}
                data={ newDomains }
                addRowClass={item => {
                    return item.newDomain ? 'bg-blue_lighen-opacity-8' : '';
                  }}
                head={[
                    { name: 'Domain', props: { width: '380px' } },
                    { name: 'Email', props: { width: '500px' } },
                    { props: { width: '80px' } },
                  ]}
                bodyItem={[
                    {
                      render: elem => {
                        const { name } = elem;
                        return (
                          <div>
                            { name }
                            <span className="tag-new">New</span>
                          </div>
                        );
                      },
                    },
                    { prop: 'email' },
                    {
                      props: { className: 'text-right' },
                      render: elem => (
                        <span className="btn-icon open-btn" onClick={ this.deleteDomain.bind(null, elem) }>
                          <i className="icons delete-regular" />
                        </span>
                      ),
                    },
                  ]}
              />
              { newDomains.length === 0 &&
                <div className="table-no-data">
                  <span>No domains added</span>
                </div>
              }
              <div className="d-flex flex-content-between">
                <button type="button" className={`btn btn-green btn-lg mr-32 btn-with-icon left`} onClick={ this.onBackPress }>
                  <i className="icons white-arrow"></i>
                  Back
                </button>
                <div className="btn-block">
                  <button type="button" className="btn btn-lg btn-white link-btn no-shadow" onClick={ this.onNextPress.bind(null, true) }>Skip</button>
                  <button
                  className={`btn btn-green btn-lg w-300 arrow-btn ${ add_order_loading ? 'loading' : '' }`}
                    onClick={ this.onNextPress.bind(null, false) }
                    type="button"
                  >
                    Next
                    <i className="icons white-arrow"></i>
                  </button>
                </div>
              </div>
            </div>
          }
          { activeState === 2 &&
            <div className="new-company-step-3">
              { order &&
                <Helmet
                  title={`${ order.id } - Order: Confirmation`}
                  htmlAttributes={{ class: '' }}
                />
              }
              <div className="new-company-ste-3-header">
                <h1>
                  <strong>Thank you!</strong>
                  <img src="img/like.png" alt="" />
                </h1>
                <p>
                  We have received your order! It usually takes up to 24h to process the order and update the public database.
                </p>
                <p>
                  Feel free to <a href="#">contact us</a> if you have any questions!
                </p>
              </div>
              <div className="new-company-step-3-info">
                <div className="step-3-info-item">
                  <span className="title">Company Name</span>
                  { order.company && <span className="sub-title">{ order.company.name }</span>}
                  { order.company &&
                    <p>
                      <span>{ order.company.line1 }</span>{ order.company.line1 ? ` ` : '' }
                      <span>{ order.company.line2 }</span>{ order.company.line2 ? ` `: '' }<br/>
                      <span>{ order.company.city }</span>{ order.company.city ? `, ` : '' }
                      <span>{ order.company.state }</span>{ order.company.state ? ` ` : '' }
                      <span>{ order.company.zip }</span>{ order.company.zip ? ` ` : 'dff' }<br/>
                      <span>{ order.company.country }</span>
                    </p>
                  }
                </div>
                <div className="step-3-info-item">
                  <span className="title">Domains</span>
                  <span className="sub-title">{ newDomains.length }</span>
                </div>
                <div className="step-3-info-item">
                  <span className="title">Order Number</span>
                  <span className="sub-title">
                    <GeneratorId length={ 4 } id={ order.id } />
                  </span>
                </div>

                <div className="step-3-info-item">
                  <span className="title">Status</span>
                  { order.status &&
                    <span className={`status capitalize ${order.status && order.status.id === 10 ? 'draft' : ''}`}>
                      { order.status.name.toLowerCase() }
                    </span>
                  }
                </div>
              </div>
              { newDomains.length > 0 &&
                <TableList
                  className="table"
                  data={ newDomains }
                  addRowClass={item => {
                    return item.newDomain ? 'bg-blue_lighen-opacity-8' : '';
                  }}
                  head={[
                    { name: 'Domain', props: { width: '380px' } },
                    { name: 'Email', props: { width: '580px' } },
                  ]}
                  bodyItem={[
                    {
                      render: elem => {
                        const { name } = elem;
                        return (
                          <div>
                            { name }
                            <span className="tag-new">New</span>
                          </div>
                        );
                      },
                    },
                    { prop: 'email' }
                  ]}
                />
              }
              <div className="btn-block">
                  <button type="button" className="btn btn-green btn-lg btn-db arrow-btn" onClick={ this.continueToPayment }>
                    Continue to Payment
                    <i className="icons white-arrow"></i>
                  </button>
                </div>
            </div>
          }
          { activeState === 3 &&
            <div>
              { order &&
                <Helmet
                  title={`${ order.id } - Order: Payment`}
                  htmlAttributes={{ class: '' }}
                />
              }
              <PaymentContainer
                onRequestClose={ this.props.onRequestClose }
                onPaymentFailure = { this.onPaymentFailure }
                onPaymentSuccess = { this.onPaymentSuccess }
                subscriptionType={ subscriptionType }
                order={ order }
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

ModalNewCompany.propTypes = {
  onRequestClose: PropTypes.func
};

ModalNewCompany.defaultProps = {
  onRequestClose: noop
};

export default compose(
  withRouter,
  withCookies,
  connect((state) => {
    return {
      account: state.account
    };
  }, {
    addDomainsToOrder: Orders.addDomainsToOrder,
    availableDomain: Domains.availableDomain,
    addCompany: Companies.createCompany,
    deleteDomain: Domains.deleteDomain,
    createOrder: Orders.createOrder,
    updateOrder: Orders.updateOrder,
    addDomain: Domains.addDomain,
    getOrder: Orders.getOrder,
    uploadDomains: Domains.uploadDomains,
    dispatch: (action) => {
      return (dispatch) => {
        dispatch(action);
      }
    }
  }),
)(ModalNewCompany);
