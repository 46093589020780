import { createAction } from 'redux-actions';

export const get_profile_associated_companies_request = createAction('GET_PROFILE_ASSOCIATED_COMPANIES_REQUEST');
export const get_profile_associated_companies_success = createAction('GET_PROFILE_ASSOCIATED_SUCCESS');
export const get_profile_associated_companies_failure = createAction('GET_PROFILE_ASSOCIATED_FAILURE');

export const get_profile_request = createAction('GET_PROFILE_REQUEST');
export const get_profile_success = createAction('GET_PROFILE_SUCCESS');
export const get_profile_failure = createAction('GET_PROFILE_FAILURE');

export const get_profile_login_data_request = createAction('GET_PROFILE_LOGIN_DATA_REQUEST');
export const get_profile_login_data_success = createAction('GET_PROFILE_LOGIN_DATA_SUCCESS');
export const get_profile_login_data_failure = createAction('GET_PROFILE_LOGIN_DATA_FAILURE');
