import { createAction } from 'redux-actions';

export const get_company_notices_request = createAction('GET_COMPANIES_NOTICES_REQUEST');
export const get_company_notices_success = createAction('GET_COMPANIES_NOTICES_SUCCESS');
export const get_company_notices_failure = createAction('GET_COMPANIES_NOTICES_FAILURE');

export const get_notices_request = createAction('GET_NOTICES_REQUEST');
export const get_notices_success = createAction('GET_NOTICES_SUCCESS');
export const get_notices_failure = createAction('GET_NOTICES_FAILURE');

export const get_notice_request = createAction('GET_NOTICE_REQUEST');
export const get_notice_success = createAction('GET_NOTICE_SUCCESS');
export const get_notice_failure = createAction('GET_NOTICE_FAILURE');

export const delete_notice_success = createAction('DELETE_NOTICE_SUCCESS');

export const get_notice_history_request = createAction('GET_NOTICE_HISTORY_REQUEST');
export const get_notice_history_success = createAction('GET_NOTICE_HISTORY_SUCCESS');
export const get_notice_history_failure = createAction('GET_NOTICE_HISTORY_FAILURE');

export const update_notice_request = createAction('UPDATE_NOTICE_REQUEST');
export const update_notice_success = createAction('UPDATE_NOTICE_SUCCESS');
export const update_notice_failure = createAction('UPDATE_NOTICE_FAILURE');

export const send_notice_email_request = createAction('SEND_NOTICE_EMAIL_REQUEST');
export const send_notice_email_success = createAction('SEND_NOTICE_EMAIL_SUCCESS');
export const send_notice_email_failure = createAction('SEND_NOTICE_EMAIL_FAILURE');

export const add_notice_link_request = createAction('ADD_NOTICE_LINK_REQUEST');
export const add_notice_link_success = createAction('ADD_NOTICE_LINK_SUCCESS');
export const add_notice_link_failure = createAction('ADD_NOTICE_LINK_FAILURE');

export const get_notice_urls_request = createAction('GET_NOTICE_URLS_REQUEST');
export const get_notice_urls_success = createAction('GET_NOTICE_URLS_SUCCESS');
export const get_notice_urls_failure = createAction('GET_NOTICE_URLS_FAILURE');

export const add_notice_from_list = createAction('ADD_NOTICE_FROM_LIST');

export const check_notices = createAction('CHECK_LATEST_NOTICE')