import PaymentsMethodItem from '../../PaymentsMethodItem/PaymentsMethodItem';
import { withRouter } from 'react-router-dom';
import React, { PureComponent } from 'react';
import { noop } from '../../../utils/utils';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

class PaymentsTypeList extends PureComponent {
  /**
   * Class constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      paymentMethos: [
        {name: 'Credit Cards', image: '/img/visa-img.svg', type: 'credit-card'},
        {name: 'Paypal', image: '/img/payment-paypal.svg', type: 'paypal', disabled: false },
        {name: 'Paxum', image: '/img/payment-paxum.svg', type: 'paxum', disabled: false },
        // {name: 'ePayments', image: '/img/payment-payments.svg', type: 'e-payments', disabled: true },
        {name: 'ACH Payment', image: '/img/payments-ach.svg', type: 'ach', disabled: false },
        {name: 'Wire Payment', image: '/img/payment-wire.svg', type: 'wire-payment', disabled: false },
        {name: 'Crypto', image: '/img/payment-crypto.svg', type: 'crypto', disabled: false }
      ]
    };
  }

  render() {
    const { activeType, continueWithoutPayment } = this.props;
    const { paymentMethos } = this.state;
    return (
      <div className="payment-method-wrapper">
        <span className="payment-methid-title">Payment Methods</span>
        { paymentMethos.map((payment, index) =>{
          return(
            <PaymentsMethodItem 
              onClick={ this.props.onTypeSelect.bind(null, payment) }
              selected={ activeType === payment.type }
              key={ `payment_method_${index}` }
              disabled={ payment.disabled }
              image={ payment.image }
              method={ payment.name }
            />
          )
          })
        }
      </div>
    )
  }
}

PaymentsTypeList.propTypes = {
  onTypeSelect: PropTypes.func,
};

PaymentsTypeList.defaultProps = {
  onTypeSelect: noop,
};

export default PaymentsTypeList;
