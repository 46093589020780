import React, { PureComponent } from 'react';
import { noop } from '../../utils/utils';
import PropTypes from 'prop-types';

class TableList extends PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      data,
      onItemClick,
      head,
      bodyItem,
      className,
      addRowClass,
    } = this.props;

    return (
      <table className={className}>
        <thead>
          <tr>
            {head.map((item, index) => {
              return (
                <th scope="col" key={`table-header-${index}`} {...item.props}>
                  {item.name || ''}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((elem, indexElem) => {
            let className = '';
            if (addRowClass && typeof addRowClass === 'function') {
              className = addRowClass(elem);
            }

            return (
              <tr
                onClick={onItemClick.bind(null, elem)}
                key={`table-list-row-${indexElem}`}
                className={`${className ? className : ''}`}
              >
                {bodyItem.map((item, index) => {
                  const propValue = item.prop && elem.hasOwnProperty(item.prop) && elem[item.prop] ? elem[item.prop] : '';
                  return (
                    <td
                      {...item.props}
                      key={`table-list-row-${indexElem}${index}`}
                    >
                      {item.prop
                        ? propValue
                        : typeof item.render === 'function'
                        ? item.render(elem, item, indexElem, index)
                        : ''}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

TableList.propTypes = {
  onItemClick: PropTypes.func,
  bodyItem: PropTypes.array,
  data: PropTypes.array,
  head: PropTypes.array,
  className: PropTypes.string,
  addRowClass: PropTypes.func,
};

TableList.defaultProps = {
  className: 'table table-hover',
  onItemClick: noop,
  bodyItem: [],
  data: [],
  head: [],
};

export default TableList;
