import * as IgnoredLinksActions from './../../actions/ignored_links';
import { handleActions } from 'redux-actions';

const initialState = {
  link_not_found: false,
  ignored_links_pagination: {},
  ignored_links_loaded: false,
  ignored_links_loading: false,
  ignored_links_notices: [],
  ignored_links_notices_loading: true,
  ignored_links_notices_pagination: {},
  ignored_links: [],
  ignored_link: {
    notice: [],
  }
};

// Reducer
const reducer = handleActions(
  {
    // Get company info
    [IgnoredLinksActions.get_ignoredlinks_success]: (state,  { payload: { pagination, results } }) => {
        return { ...state, 
            ignored_links_pagination: pagination,
            ignored_links_loading: true,
            ignored_links: results,
        };
    },
    [IgnoredLinksActions.get_ignored_link_success]: (state, { payload: { ignored_link }}) => {
      return { ...state,
        ignored_link: ignored_link,
      }
    },
    [IgnoredLinksActions.get_ignoredlinksnotices_success]: (state,  { payload: { pagination, results } }) => {
      return { ...state, 
          ignored_links_notices_pagination: pagination,
          ignored_links_notices_loading: true,
          ignored_links_notices: results,
      };
    },
  },
  initialState,
);

export default reducer;
