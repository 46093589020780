import * as DomainsActions from './../../actions/domains';
import * as AccountActions from './../../actions/account';
import { handleActions } from 'redux-actions';

const initialState = {
  domain_not_found: false,
  domains_loading: false,
  domains_loaded: false,
  domains_pagination: {},
  domains: [],

  // Domain info
  domain: {},
  domain_loading: false,
  domain_loaded: false,
  domain_error: null,

  // Domain orders
  domain_orders_pagination: {},
  domain_orders_loading: false,
  domain_orders_loaded: false,
  domain_orders: [],

  // Domain notices
  domain_notices_pagination: {},
  domain_notices_loading: false,
  domain_notices_loaded: false,
  domain_notices: [],

  // Domain histories
  domain_histories_pagination: {},
  domain_histories_loading: false,
  domain_histories_loaded: false,
  domain_histories: [],
};

// Reducer
const reducer = handleActions(
  {
    // Update domain
    [DomainsActions.update_domain_success]: (state, { payload: {data, domain_id} }) => {
      let payload = {};

      if (domain_id === state.domain.id) {
        payload.domain = {
          ...state.domain,
          ...data,
        };
      }

      payload.domains = state.domains.map((domain) => {
        if (domain.id === domain_id) {
          domain = {
            ...domain,
            ...data,
          };
        }
        
        return domain;
      })

      return { ...state,
        ...payload,
      };
    },

    // Get domain histories
    [DomainsActions.get_domain_histories_request]: (state, { payload }) => {
      return { ...state,
        domain_histories_loading: true,
        ...payload
      };
    },
    [DomainsActions.get_domain_histories_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        domain_histories_pagination: pagination,
        domain_histories_loading: false,
        domain_histories_loaded: true,
        domain_histories: results
      };
    },
    [DomainsActions.get_domain_histories_failure]: (state, { payload }) => {
      return { ...state,
        domain_histories_loading: false,
        ...payload
      };
    },

    // Get domain notices
    [DomainsActions.get_domain_notices_request]: (state, { payload }) => {
      return { ...state,
        domain_notices_loading: true,
        ...payload
      };
    },
    [DomainsActions.get_domain_notices_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        domain_notices_pagination: pagination,
        domain_notices_loading: false,
        domain_notices_loaded: true,
        domain_notices: results
      };
    },
    [DomainsActions.get_domain_notices_failure]: (state, { payload }) => {
      return { ...state,
        domain_notices_loading: false,
        ...payload
      };
    },

    // Get domain orders
    [DomainsActions.get_domain_orders_request]: (state, { payload }) => {
      return { ...state,
        domain_orders_loading: true,
        ...payload
      };
    },
    [DomainsActions.get_domain_orders_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        domain_orders_pagination: pagination,
        domain_orders_loading: false,
        domain_orders_loaded: true,
        domain_orders: results
      };
    },
    [DomainsActions.get_domain_orders_failure]: (state, { payload }) => {
      return { ...state,
        domain_orders_loading: false,
        ...payload
      };
    },

    // Get domain by id
    [DomainsActions.get_domain_request]: (state, { payload }) => {
      return { ...state,
        domain_not_found: false,
        domain_loading: true,
        domain_error: null,
        ...payload
      };
    },
    [DomainsActions.get_domain_success]: (state, { payload }) => {
      return { ...state,
        ...payload
      };
    },
    [DomainsActions.get_domain_failure]: (state, { payload }) => {
      return { ...state,
        ...payload
      };
    },

    // Add domain from list
    [DomainsActions.add_domain_from_list]: (state, { payload: { domain_id, domain = null } }) => {
      let newdomain = null;

      if (domain) {
        newdomain = domain;
      } else {
        state.domains.map((item) => {
          if (item.id === domain_id) {
            newdomain = item;
          }
          return item;
        });
      }

      if (newdomain) {
        return { ...state,
          domain: newdomain,
          domain_not_found: false
        };
      } else {
        return state;
      }
    },
    // Get company info
    [DomainsActions.get_domains_request]: (state, { payload }) => {
      return { ...state,
        domains_loading: true
      };
    },
    [DomainsActions.get_domains_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        domains_pagination: pagination,
        domains_loading: false,
        domains_loaded: true,
        domains: results
      };
    },
    [DomainsActions.get_domains_failure]: (state, { payload }) => {
      return { ...state,
        domains_loading: false
      };
    },

    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default reducer;
