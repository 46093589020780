import * as RepresentativeActions from './../../actions/representative';
import * as CompaniesActions from './../../actions/companies';
import * as DomainsActions from './../../actions/domains';
import * as NoticesActions from './../../actions/notices';
import * as HistoryActions from './../../actions/history';
import * as AccountActions from './../../actions/account';
import * as OrdersActions from './../../actions/orders';

import { handleActions } from 'redux-actions';

const initialState = {
  company_not_found: false,
  companies_pagination: {},
  companies_loaded: false,
  companies_loading: false,
  companies: [],

  // Admin companies
  admin_companies_pagination: {},
  admin_companies_loading: false,
  admin_companies_loaded: false,
  admin_companies: [],

  // Company domains
  company_domains_pagination: {},
  company_domains_loading: false,
  company_domains_loaded: false,
  company_domains_id: null,
  company_domains: [],

  // Company create event domains
  company_create_event_domains_pagination: {},
  company_create_event_domains_loading: false,
  company_create_event_domains_loaded: false,
  company_create_event_domains_id: null,
  company_create_event_domains: [],

  // Company orders
  company_orders_pagination: {},
  company_orders_loading: false,
  company_orders_loaded: false,
  company_orders: [],

  // Company notices
  company_notices_pagination: {},
  company_notices_loading: false,
  company_notices_loaded: false,
  company_notices: [],

  // Company events
  company_events_pagination: {},
  company_events_loading: false,
  company_events_loaded: false,
  company_events: [],

  // Company histories
  company_histories_pagination: {},
  company_histories_loading: false,
  company_histories_loaded: false,
  company_histories: [],

  // Company info
  company_loading: false,
  company_loaded: false,
  company_id: null,
  company: {},

  // Company invoices
  company_invoices: [],
  company_invoices_loading: false,
  company_invoices_loaded: false,
  company_invoices_pagination: {},

  // Amendment company info
  amendment_company_loading: false,
  amendment_company_loaded: false,
  amendment_company_id: null,
  amendment_company: {},

  // Amendment order
  amendment_company_order_loading: false,
  amendment_company_order_loaded: false,
  amendment_company_order: {},

  // Amendment company domains
  amendment_company_domains_pagination: {},
  amendment_company_domains_loading: false,
  amendment_company_domains_loaded: false,
  amendment_company_domains_id: null,
  amendment_company_domains: [],

  // Company billing info info
  company_billing_loading: false,
  company_billing_loaded: false,
  company_billing: {},

  company_notes_loading: false,
  company_notes_pagination: {},
  company_notes_loaded: false,
  company_notes: [],
  company_note_create_loading: false,

  // User company permissions
  userCompanyPermissions: {
    roles: [],
  },
};

// Reducer
const reducer = handleActions(
  {
     // Update company billing information
     [CompaniesActions.update_company_billing_info_success]: (state, { payload: { company_id, data = {} } }) => {
      let company_billing = state.company_billing || {};
      let company = state.company;

      if (company.id === company_id) {
        company_billing = {
          ...state.company_billing,
          ...data,
        };
      }

      return { ...state,
        company_billing: {
          ...state.company_billing,
          ...company_billing,
        },
      };
    },

    // Delete company representtaive
    [RepresentativeActions.delete_representative_success]: (state, { payload: { representativeId } }) => {
      let company = state.company;

      company.representatives = company.representatives.filter((item) => item.id !== representativeId);

      return { ...state,
        company,
      };
    },

    // Update company representtaive
    [RepresentativeActions.put_representative_success]: (state, { payload: { type, representativeId } }) => {
      let company = state.company;

      company.representatives = company.representatives.map((representative) => {
        if (representative.id === representativeId) {
          representative.type.name = type.label;
          representative.type.id = type.value;
        }

        return representative;
      });

      return { ...state,
        company,
      };
    },

    // Select amendment default data
    [CompaniesActions.select_amendment_default_data]: (state, { payload }) => {
      return { ...state,
        ...payload
      };
    },
    // create amendment order
    [OrdersActions.create_amendment_order_request]: (state, { payload }) => {
      return { ...state,
        company_billing_loading: true,
        ...payload
      };
    },
    [OrdersActions.create_amendment_order_success]: (state, { payload: { amendment_company_order } }) => {
      return { ...state,
        amendment_company_order_loading: false,
        amendment_company_order_loaded: true,
        amendment_company_order
      };
    },
    [OrdersActions.create_amendment_order_failure]: (state, { payload }) => {
      return { ...state,
        amendment_company_order_loading: false,
        ...payload
      };
    },

    // Get company billing info
    [CompaniesActions.get_company_billing_info_request]: (state, { payload }) => {
      return { ...state,
        company_billing_loading: true,
        ...payload
      };
    },
    [CompaniesActions.get_company_billing_info_success]: (state, { payload: { company_billing } }) => {
      return { ...state,
        company_billing_loading: false,
        company_billing_loaded: true,
        company_billing
      };
    },
    [CompaniesActions.get_company_billing_info_failure]: (state, { payload }) => {
      return { ...state,
        company_billing_loading: false,
        ...payload
      };
    },

    // Get company orders
    [CompaniesActions.get_company_orders_request]: (state, { payload }) => {
      return { ...state,
        company_orders_loading: true,
        ...payload
      };
    },
    [CompaniesActions.get_company_orders_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        company_orders_pagination: pagination,
        company_orders_loading: false,
        company_orders_loaded: true,
        company_orders: results
      };
    },
    [CompaniesActions.get_company_orders_failure]: (state, { payload }) => {
      return { ...state,
        company_orders_loading: false,
        ...payload
      };
    },

    // Get company info
    [CompaniesActions.update_company_request]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        company_not_found: false
      };
    },
    [CompaniesActions.update_company_success]: (state, { payload }) => {
      let { company, companies } = state;

      if (company.id === payload.company_id) {
        company = {
          ...company,
          ...payload.data
        };
      }

      companies = companies.map((item) => {
        if (item.id === payload.company_id) {
          item = {
            ...item,
            ...payload.data,
          };
        }

        return item;
      });

      return { ...state,
        ...payload,
        companies,
        company
      };
    },
    [CompaniesActions.get_company_failure]: (state, { payload }) => {
      return { ...state,
        company_loading: false,
        ...payload
      };
    },

    // Add company from list
    [CompaniesActions.add_company_from_list]: (state, { payload: { company_id } }) => {
      let company = null;
      state.companies.map((item) => {
        if (item.id === company_id) {
          company = item;
        }
        return item;
      });

      if (company) {
        return { ...state,
          company_not_found: false,
          company
        };
      } else {
        return state;
      }
    },

    // Get amendment company info
    [CompaniesActions.get_amendment_company_request]: (state, { payload }) => {
      return { ...state, amendment_company_loading: true };
    },
    [CompaniesActions.get_amendment_company_success]: (state, { payload }) => {
      return { ...state,
        amendment_company_loading: false,
        ...payload
      };
    },
    [CompaniesActions.get_amendment_company_failure]: (state, { payload }) => {
      return { ...state,
        amendment_company_loading: false,
        ...payload
      };
    },

    // Get company info
    [CompaniesActions.get_company_request]: (state, { payload }) => {
      return { ...state, company_loading: true };
    },
    [CompaniesActions.get_company_success]: (state, { payload }) => {
      return { ...state,
        company_loading: false,
        ...payload
      };
    },
    [CompaniesActions.get_company_failure]: (state, { payload }) => {
      return { ...state,
        company_loading: false,
        ...payload
      };
    },

    // Get company histories
    [HistoryActions.get_company_history_request]: (state, { payload }) => {
      return { ...state,
        company_histories_loading: true,
        ...payload
      };
    },
    [HistoryActions.get_company_history_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        company_histories_pagination: pagination,
        company_histories_loading: false,
        company_histories_loaded: true,
        company_histories: results
      };
    },
    [HistoryActions.get_company_history_failure]: (state, { payload }) => {
      return { ...state,
        company_histories_loading: false,
        ...payload
      };
    },

    // Get company events
    [CompaniesActions.get_company_events_request]: (state, { payload }) => {
      return { ...state,
        company_events_loading: true,
        ...payload
      };
    },
    [CompaniesActions.get_company_events_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        company_events_pagination: pagination,
        company_events_loading: false,
        company_events_loaded: true,
        company_events: results
      };
    },
    [CompaniesActions.get_company_events_failure]: (state, { payload }) => {
      return { ...state,
        company_events_loading: false,
        ...payload
      };
    },

    // Get company notices
    [NoticesActions.get_company_notices_request]: (state, { payload }) => {
      return { ...state,
        company_notices_loading: true,
        ...payload
      };
    },
    [NoticesActions.get_company_notices_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        company_notices_pagination: pagination,
        company_notices_loading: false,
        company_notices_loaded: true,
        company_notices: results
      };
    },
    [NoticesActions.get_company_notices_failure]: (state, { payload }) => {
      return { ...state,
        company_notices_loading: false,
        ...payload
      };
    },

    // Get amendment company domains
    [DomainsActions.get_amendment_company_domains_request]: (state, { payload }) => {
      return { ...state,
        amendment_company_domains_loading: true,
        ...payload
      };
    },
    [DomainsActions.get_amendment_company_domains_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        amendment_company_domains_pagination: pagination,
        amendment_company_domains_loading: false,
        amendment_company_domains_loaded: true,
        amendment_company_domains: results
      };
    },
    [DomainsActions.get_amendment_company_domains_failure]: (state, { payload }) => {
      return { ...state, ...payload };
    },

    // Get company domains
    [DomainsActions.get_company_domains_request]: (state, { payload }) => {
      return { ...state,
        company_domains_loading: true,
        ...payload
      };
    },
    [DomainsActions.get_company_domains_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        company_domains_pagination: pagination,
        company_domains_loading: false,
        company_domains_loaded: true,
        company_domains: results
      };
    },
    [DomainsActions.get_company_domains_failure]: (state, { payload }) => {
      return { ...state, ...payload };
    },

    // Get company event domains
    [DomainsActions.get_create_events_domains_request]: (state, { payload }) => {
      return { ...state,
        company_create_event_domains_loading: true,
        ...payload
      };
    },
    [DomainsActions.get_create_events_domains_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        company_create_event_domains_pagination: pagination,
        company_create_event_domains_loading: false,
        company_create_event_domains_loaded: true,
        company_create_event_domains: results
      };
    },
    [DomainsActions.get_create_events_domains_failure]: (state, { payload }) => {
      return { ...state, ...payload };
    },

    // Get companies list
    [CompaniesActions.get_user_companies_request]: (state, { payload }) => {
      return { ...state,
        companies_loading: true,
        ...payload
      };
    },
    [CompaniesActions.get_user_companies_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        companies: pagination.page > 1 ? state.companies.concat(results) : results,
        companies_pagination: pagination,
        companies_loading: false,
        companies_loaded: true
      };
    },
    [CompaniesActions.get_user_companies_failure]: (state, { payload }) => {
      return { ...state,
        companies_loading: false,
        ...payload
      };
    },

    // Get admin companies list
    [CompaniesActions.get_admin_companies_request]: (state, { payload }) => {
      return { ...state,
        admin_companies_loading: true,
        ...payload
      };
    },
    [CompaniesActions.get_admin_companies_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        admin_companies: results,
        admin_companies_pagination: pagination,
        admin_companies_loading: false,
        admin_companies_loaded: true
      };
    },
    [CompaniesActions.get_admin_companies_failure]: (state, { payload }) => {
      return { ...state,
        admin_companies_loading: false,
        ...payload
      };
    },

    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...initialState,
      };
    },

    // Get company invoices
    [CompaniesActions.get_company_invoices_request]: (state, { payload }) => {
      return { ...state,
        company_invoices_loading: true,
        ...payload
      };
    },
    [CompaniesActions.get_company_invoices_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        company_invoices_pagination: pagination,
        company_invoices_loading: false,
        company_invoices_loaded: true,
        company_invoices: results
      };
    },
    [CompaniesActions.get_company_invoices_failure]: (state, { payload }) => {
      return { ...state,
        company_invoices_loading: false,
        ...payload
      };
    },

    [CompaniesActions.get_company_notes_request]: (state) => {
      return { ...state,
        company_notes_loading: true,
      };
    },
    [CompaniesActions.get_company_notes_success]: (state, { payload: { pagination, results=[] } }) => {
      return { ...state,
        company_notes_pagination: pagination,
        company_notes_loading: false,
        company_notes_loaded: true,
        company_notes: results,
      };
    },
    [CompaniesActions.get_company_notes_failure]: (state) => {
      return { ...state,
        company_notes_loading: false,
      };
    },

    [CompaniesActions.add_company_note_request]: (state) => {
      return { ...state,
        company_note_create_loading: true,
      };
    },
    [CompaniesActions.add_company_note_success]: (state, { payload: { note={}, type } }) => {
      const pagination = state.company_notes_pagination;
      const company_notes = state.company_notes;

      return { ...state,
        company_notes: company_notes.concat([note]),
        company_notes_pagination: {
          ...pagination,
          max_page: Math.ceil(pagination.count + 1/pagination.per_page),
          count: pagination.count + 1,
        },
        company_note_create_loading: false,
      };
    },
    [CompaniesActions.add_company_note_failure]: (state) => {
      return { ...state,
        company_note_create_loading: false,
      };
    },

  },
  initialState,
);

export default reducer;
