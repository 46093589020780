import * as AccountActions from '../actions/account';
import * as ProfileActions from '../actions/profile';
import * as UsersActions from '../actions/users';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Users {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Get user login data
   * @param {*} param0
   */
  getUserLoginData({
    resolve = noop,
    reject = noop,
    headers = {},
    user_id,
    per_page = 10,
    page = 1,
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };
    const query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    return (dispatch, getState, { axios }) => {
      dispatch(ProfileActions.get_profile_login_data_request({ user_id }));

      return axios
        .get(`${API_URL}/users/${user_id}/login_data/${query}`, authorization)
        .then((response) => {
          const count = response.data.count;

          dispatch(ProfileActions.get_profile_login_data_success({
            results: response.data.results.map((item) => {
              let info = {};
              if (item.ip_api_data.error !== true) {
                info = item.ip_api_data || {};
              } else {
                info.ip = item.ip_api_data.ip;
              }

              return {
                date: item.date,
                ...info,
              };
            }),
            user_id,
            pagination: {
              max_page: Math.ceil(count / per_page),
              per_page,
              count,
              page,
            },
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(ProfileActions.get_profile_login_data_failure({ user_id }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Delete user
   * @param {*} param0
   */
  deleteUser({
    resolve = noop,
    reject = noop,
    headers = {},
    user_id,
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(UsersActions.delete_user_request({ user_id }));

      return axios
        .delete(`${API_URL}/users/${user_id}/delete/`, authorization)
        .then((response) => {
          dispatch(UsersActions.delete_user_success({
            user_id,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(UsersActions.delete_user_failure({ user_id }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get associated companies
   * @param {*} param0
   */
  getUserAssociatedCompanies({
    resolve = noop,
    reject = noop,
    headers = {},
    user_id,
    per_page = 10,
    page = 1,
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };
    const query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    return (dispatch, getState, { axios }) => {
      dispatch(ProfileActions.get_profile_associated_companies_request({ user_id }));

      return axios
        .get(`${API_URL}/users/${user_id}/companies/${query}`, authorization)
        .then((response) => {
          const companies = response.data.results;
          const count = response.data.count;

          dispatch(ProfileActions.get_profile_associated_companies_success({
            results: companies,
            user_id,
            pagination: {
              max_page: Math.ceil(count / per_page),
              per_page,
              count,
              page,
            },
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(ProfileActions.get_profile_associated_companies_failure({ user_id }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Update user
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param user_id
   * @returns {function(*, *, {axios: *})}
   */
  updateUser({
    resolve = noop,
    reject = noop,
    headers = {},
    user_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(AccountActions.put_user_request({ user_id, data }));
      return axios
        .put(`${API_URL}/users/${user_id}/`, data, autorization)
        .then((response) => {
          dispatch(AccountActions.put_user_success({ data, user_id }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(AccountActions.put_user_failure({ user_id }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Update user password
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param user_id
   * @returns {function(*, *, {axios: *})}
   */
  updateUserPassword({
    resolve = noop,
    reject = noop,
    headers = {},
    user_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .put(`${API_URL}/users/${user_id}/update-password/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Update user image
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  updateUserAvatar({
    resolve = noop,
    reject = noop,
    headers = {},
    data,
    dispatchAction = true,
  }) {
    const autorization = {
      headers: {
        Authorization: `JWT ${headers.token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(AccountActions.user_update_image_request({ }));
      }

      return axios
        .post(`${API_URL}/users/profile-image/`, data, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(AccountActions.user_update_image_success({
              image: response.data,
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(AccountActions.user_update_image_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Delete user image
   * @param resolve
   * @param reject
   * @param headers
   * @param file_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  deleteUserAvatar({
    resolve = noop,
    reject = noop,
    headers = {},
    file_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(AccountActions.delete_user_image_request({ }));
      }
      return axios
        .delete(`${API_URL}/files/${file_id}/`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(AccountActions.delete_user_image_success({}));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(AccountActions.delete_user_image_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get user profile
   * @param {*} param0
   */
  getUserProfile({
    resolve = noop,
    reject = noop,
    headers = {},
    profile_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(ProfileActions.get_profile_request({ profile_id }));
      return axios
        .get(`${API_URL}/users/${profile_id}/`, autorization)
        .then((response) => {
          dispatch(ProfileActions.get_profile_success({
            profile: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(ProfileActions.get_profile_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get users statuses
   * @param {*} param0
   */
  getUsersStatuses({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 50,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/users/statuses/`, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  updateUserStatus({
    resolve = noop,
    reject = noop,
    headers = {},
    profile_id = null,
    status_id = null,
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };

    const data = {
      user_id: profile_id,
      status_id: status_id,
    };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/users/${profile_id}/status/`, data, authorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  getUserStatuses({
    resolve = noop,
    reject = noop,
    headers = {},
    user_id = null,
    page = 1,
    per_page = 50,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    const query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/users/${user_id}/statuses${query}`, autorization)
        .then((response) => {
          dispatch(UsersActions.user_statuses_success({
            page,
            per_page,
            results: response.data.results,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            },
          }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get users
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param search
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getUsers({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search,
    params = {},
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if (search) {
      query += `&search=${search}`;
    }

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(UsersActions.get_users_request({ search, page, per_page }));
      }
      return axios
        .get(`${API_URL}/users/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(UsersActions.get_users_success({
              search,
              page,
              per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(UsersActions.get_users_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new Users();
