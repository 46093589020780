import { renderField, renderFieldInputMask } from './form-elements';
import { validateCreditCardForm } from '../utils/validators';
import { Field, reduxForm, change } from 'redux-form';
import { noop, GetCardType } from '../utils/utils';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';

const maskTypes = {
  'visa_electron': '9999 9999 9999 9999',
  'mastercard': '9999 9999 9999 9999',
  'diners_club': '9999 9999 9999 99',
  'discover': '9999 9999 9999 9999',
  'diners': '9999 9999 9999 99',
  'visa': '9999 9999 9999 9999',
  'jcb': '9999 9999 9999 9999',
  'amex': '9999 999999 99999',
};

class CreditCardForm extends PureComponent {
  /**
   * Class contructor
   */
  constructor(props) {
    super(props);
    this.onInputMaskChange = this.onInputMaskChange.bind(this);
  }

  /**
   * On input mask change
   * @param {*} event
   */
  onInputMaskChange(event) {
    const { dispatch } = this.props;
    
    dispatch(change('credit-card-form', 'number', 'event.target.value'));
  }

  render() {
    const { handleSubmit, continueWithoutPayment, values, loading, btnText } = this.props;
    const typeCard = GetCardType(values.number || '');

    return (
      <form className="credit-card-form">
        <span className="credit-card-title">Credit Cards</span>
        <div className="credit-card-img-block">
          <img src="/img/credit-cards-big-img.svg" alt="credit card"/>
        </div>
        { this.props.errorVisible &&
          <div className="existing-domain-warning mb-19 payment-form">
            <div className="warning-info">
              <i className="icons warning-white"></i>
              <span>{this.props.errorText}</span>
            </div>
            <span className="close-existing-domain-warning" onClick={this.props.onCloseError}>
              <i className="icons close-regular"></i>
            </span>
          </div>
        }
        <div className="form-group mb-25">
          <label htmlFor="card_name">Name on card</label>
          <div className="input-field">
            <Field
              className="form-control form-control-lg"
              component={ renderField }
              name="card_name"
              id="card_name"
              type="text"
            />
          </div>
        </div>
        <div className="form-group mb-25">
          <label htmlFor="number">Card number</label>
          <div className="input-field">
            <Field
              className="form-control form-control-lg"
              component={ renderFieldInputMask }
              inputMaskProps={{
                mask: maskTypes[typeCard] || '9999 9999 9999 9999',
                onInputMaskChange: this.props.onCloseError.bind(null, 'CHANGE'),
                maskChar: null,
              }}
              name="number"
              id="number"
            />
          </div>
        </div>
        <div className="mb-32 d-flex">
          <div className="form-group mb-0 mr-24 w-188">
            <label htmlFor="expiration_date">expiration date</label>
            <div className="input-field">
              <Field
                className="form-control form-control-lg"
                component={ renderFieldInputMask }
                inputMaskProps={{
                  onInputMaskChange: this.props.onCloseError.bind(null, 'CHANGE'),
                  placeholder: 'MM / YYYY',
                  mask: '99 / 9999',
                  maskChar: null
                }}
                name="expire_date"
              />
            </div>
          </div>
          <div className="form-group mb-0 w-178">
            <label htmlFor="security_code">security code</label>
            <div className="input-field">
              <Field
                className="form-control form-control-lg"
                component={ renderFieldInputMask }
                inputMaskProps={{
                  onInputMaskChange: this.props.onCloseError.bind(null, 'CHANGE'),
                  mask: typeCard === 'amex' ? '9999' : '999',
                  placeholder: 'CVV',
                  maskChar: null,
                }}
                name="cvv"
              />
            </div>
          </div>
        </div>
        <button type="button" className={`btn btn-green btn-lg btn-db arrow-btn ${ loading ? 'loading' : '' }`} onClick={ handleSubmit }>
          { continueWithoutPayment ? 'Continue without payment' : btnText }
          <i className="icons white-arrow"></i>
        </button>
      </form>
    );
  }
}

CreditCardForm.propTypes = {
  continueWithoutPayment: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onCloseError: PropTypes.func,
  showClose: PropTypes.bool,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  btnText: PropTypes.string,
};

CreditCardForm.defaultProps = {
  continueWithoutPayment: false,
  btnText: "Pay Now",
  onCloseError: noop,
  onCloseClick: noop,
  showClose: false,
  disabled: false,
  onSubmit: noop,
};

export default compose(
  reduxForm({
    validate: validateCreditCardForm,
    form: 'credit-card-form',
    initialValues: {
    }
  }),
  withCookies,
  connect(
    state => {
      return {
        values: state.form['credit-card-form'].values || {},
        account: state.account,
      };
    },
    {
      dispatch: (action) => {
        return (dispatch) => dispatch(action);
      }
    },
  ),
)(CreditCardForm);
