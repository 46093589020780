import PaymentTypeContainer from './PaymentTypeContainer/PaymentTypeContainer';
import PaymentsTypeList from './PaymentsTypeList/PaymentsTypeList';
import { noop, closeDropdown } from '../../utils/utils';
import { Payment, Orders } from '../../services';
import { withRouter } from 'react-router-dom';
import React, { PureComponent } from 'react';
import messages from '../../utils/messages';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { compose } from 'redux';

const errors = [
  messages.error.order.order_pay_error_1,
  messages.error.order.order_pay_error_2,
  messages.error.order.order_pay_error_3,
];

class PaymentContainer extends PureComponent {
  /**
   * Class constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.onChangeSubscriptionType = this.onChangeSubscriptionType.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onTypeSelect = this.onTypeSelect.bind(this);
    this.onCloseError = this.onCloseError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toastActive = null;

    this.state = {
      subscriptionType: props.subscriptionType || 'year',
      continueWithoutPayment: 0,
      activeType: 'credit-card',
      errorVisible: false,
      errorText: '',
      loading: false,
      options: [
        { label: "$960/year", value: 'year' },
        { label: "$100/month", value: 'month' },
      ],
    };
  }

  get getDefaultSubscriptionType() {
    const { subscriptionType } = this.state;

    return this.state.options
      .filter((item) => item.value === subscriptionType)[0];
  }

  get getSubmitBtnText() {
    const { subscriptionType } = this.state;
    const amount = subscriptionType === 'year' ? 12 * 80 : 100;


    return `Pay Now $${amount}`;
  }

  /**
   * On subscription type change
   * @param {*} option
   */
  onChangeSubscriptionType(option) {
    this.setState({
      subscriptionType: option.value,
    });
  }

  /**
   * On input change
   * @param {*} option
   */
  onInputChange(event) {
    const e = event.nativeEvent;

    if(e.target.checked) {
      this.onChangeSubscriptionType({
        value: e.target.value,
      });
    }
  }

  /**
   * On payment type select
   * @param {*} payment
   */
  onTypeSelect(payment) {
    const payload = {
      activeType: payment.type,
    };

    if (!payment.disabled) {
      if (this.state.activeType !== payload.type) {
        payload.continueWithoutPayment = 0;
        payload.errorVisible = false;
      }

      this.setState(payload);
    }
  }

  /**
   * On payment type submit event
   * @param {*} payment_type
   * @param {*} values
   */
  onSubmit(payment_type, values) {
    const { onRequestClose, cookies, createPayment, order, onPaymentSuccess, onPaymentFailure, getOrder, dispatch } = this.props;
    const headers = { token: localStorage.getItem('token') };
    const { subscriptionType } = this.state;

    const payload = {
      period: subscriptionType,
      order_id: order.id,
      payment_type,
    };

    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    if (payment_type === 1) {
      const expire = values.expire_date.split('/');
      const number = parseInt(values.number.split(' ').join(''));

      payload.number = number;
      payload.name = values.card_name;
      payload.expiry_month = parseInt(expire[0]);
      payload.expiry_year = parseInt(expire[1]);
    }

    if (payment_type === 1 && values.cvv) {
      payload.cvv = parseInt(values.cvv);
    }

    createPayment({
      data: payload,
      headers,
      resolve: () => {
        getOrder({
          order_id: order.id,
          headers,
          resolve: (res) => {
            onPaymentSuccess({payment_type});
            this.setState({
              loading: false,
            });
          },
          reject: () => {
            onPaymentSuccess();
            this.setState({
              loading: false,
            });
          }
        });
      },
      reject: (error) => {
        if (this.state.continueWithoutPayment < 3 && payment_type !==  5) {
          return this.setState({
            continueWithoutPayment: this.state.continueWithoutPayment + 1,
            loading: false,
          }, () => {
            const error = errors[this.state.continueWithoutPayment - 1];

            this.setState({
              errorText: error ? error : errors[2],
              errorVisible: true,
            });
          });
        }

        this.props.updateOrder({
          headers,
          order_id: order.id,
          data: {
            plan_id: subscriptionType,
          },
          resolve: (res) => {
            onPaymentFailure(error);
            this.setState({
              loading: false,
            });
          },
          reject: (error) => {
            onPaymentFailure(error);
            this.setState({
              loading: false,
            });
          },
        });

        if (payment_type ===  5) {
          onRequestClose();
        }
      },
    });
  }

  /**
   * On close error
   * @param {*} action
   */
  onCloseError(action) {
    const payload = {
      errorVisible: false,
      errorText: '',
    };

    if (action && action === "CHANGE") {
      payload.continueWithoutPayment = 0;
    }

    this.setState(payload);
  }

  render() {
    const { order, paymentMethodContainerClass, showPricingRadio } = this.props;
    const { activeType, continueWithoutPayment, subscriptionType } = this.state;
    const company = order.company || {};

    return (
      <div className="payment-container">
        <div className={`card-block payment-introduction-card mb-40 ${showPricingRadio ? 'pricing-input-select-container' : ''}`}>
          { !showPricingRadio &&
            <div className="payment-introduction">
              <div className="payment-introduction-item">
                <span className="title">Company Name</span>
                <span className="description">{ company.name }</span>
              </div>
              <div className="payment-introduction-item">
                <span className="title">Domains</span>
                <span className="description">{ order.domains.length }</span>
              </div>
              <div className="payment-introduction-item mr-24">
                <span className="title">Price</span>
                <span>
                  <Select
                    onMenuClose={ closeDropdown.bind(null, this.role) }
                    onChange={ this.onChangeSubscriptionType }
                    ref={(node) => this.role = node }
                    defaultValue={ this.getDefaultSubscriptionType }
                    className="react-select-container"
                    options={ this.state.options }
                    classNamePrefix="react-select"
                    blurInputOnSelect={ true }
                    placeholder="Select price"
                    isSearchable={ false }
                  />
                </span>
              </div>
              <div className="payment-introduction-item">
                <span className="title">Order Status</span>
                <span className={`status capitalize ${order.status && order.status.id === 10 ? 'draft' : ''}`}>
                  { order && order.status && order.status.name ? order.status.name.toLowerCase() : 'waiting for payment'}
                </span>
              </div>
            </div>
          }
          { showPricingRadio &&
            <div className="pricing-input-select">
              <div className="input-selection">
                <input
                  defaultChecked={subscriptionType === 'month'}
                  onChange={this.onInputChange }
                  value="month"
                  type="radio"
                  name="price"
                  id="month"
                />
                <label htmlFor="month">$100 / month</label>
              </div>
              <div className="input-selection">
                <input
                  defaultChecked={subscriptionType === 'year'}
                  onChange={this.onInputChange }
                  type="radio" id="year"
                  value={'year'}
                  name="price"
                />
                <label htmlFor="year">$960 / year</label>
              </div>
            </div>
          }
        </div>

        <div className={`payment-method-container ${paymentMethodContainerClass}`}>
          <div className="payment-method-block">
            <PaymentsTypeList
              onTypeSelect={ this.onTypeSelect }
              activeType={ activeType }
            />
            <PaymentTypeContainer
              continueWithoutPayment={ continueWithoutPayment === 3 }
              errorVisible={this.state.errorVisible}
              onCloseError={this.onCloseError}
              errorText={this.state.errorText}
              btnText={this.getSubmitBtnText}
              loading={ this.state.loading }
              onSubmit={ this.onSubmit }
              activeType={ activeType }
            />
          </div>
        </div>
      </div>
    )
  }
}

PaymentContainer.propTypes = {
  paymentMethodContainerClass: PropTypes.string,
  subscriptionType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onPaymentSuccess: PropTypes.func,
  onPaymentFailure: PropTypes.func,
  onRequestClose: PropTypes.func,
  order: PropTypes.object
};

PaymentContainer.defaultProps = {
  paymentMethodContainerClass: '',
  onPaymentSuccess: noop,
  onPaymentFailure: noop,
  onRequestClose: noop,
  subscriptionType: 1,
  order: {
    domains: [],
    status: {}
  }
};

export default compose(
  withRouter,
  withCookies,
  connect((state) => {
    return {
      account: state.account
    };
  }, {
    createPayment: Payment.createPayment,
    getOrder: Orders.getOrder,
    updateOrder: Orders.updateOrder,
    dispatch: (action) => {
      return (dispatch) => {
        dispatch(action);
      }
    }
  }),
)(PaymentContainer);
