import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../utils/utils';

class PaymentsMethodItem extends PureComponent {
  /**
   * Class constructor
   * @param props
   */
  constructor(props) {
    super(props);
    
  }

  render() { 
    const { image, method, selected, disabled } = this.props;
    return (
      <div className={`payment-method-item ${selected ? 'selected' : ''} ${ disabled ? 'disabled' : '' }`} onClick={ this.props.onClick }>
        <div className="img-block">
          <img src={image} alt="visa"/>
        </div>
        <span className="description">{method}</span>
      </div>
    );
  }
}

PaymentsMethodItem.propTypes = {
  onClick: PropTypes.func,
  image: PropTypes.string,
  method: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};

PaymentsMethodItem.defaultProps = {
  disabled: false,
  onClick: noop
};

export default PaymentsMethodItem;