import * as ProfileActions from './../../actions/profile';
import * as AccountActions from './../../actions/account';
import { handleActions } from 'redux-actions';

const initialState = {
  // Profile associated companies
  profile_companies_pagination: {},
  profile_companies_loading: false,
  profile_companies_loaded: false,
  profile_companies: [],

  // Profile login data
  profile_login_data_pagination: {},
  profile_login_data_loading: false,
  profile_login_data_loaded: false,
  profile_login_data: [],

  // Profile data
  profile_loading: false,
  profile: { 
    info: [],
  },
};

// Reducer
const reducer = handleActions(
  {
    // get profile login data
    [ProfileActions.get_profile_login_data_request]: (state, { payload: { user_id }}) => {
      return { ...state,
        profile_login_data_loading: true,
      };
    },
    [ProfileActions.get_profile_login_data_success]: (state, { payload: { results, pagination } }) => {
      return { ...state,
        profile_login_data: pagination.page > 1 ? state.profile_login_data.concat(results) : results,
        profile_login_data_pagination: pagination,
        profile_login_data_loading: false,
      };
    },
    [ProfileActions.get_profile_login_data_failure]: (state) => {
      return { ...state,
        profile_login_data_loading: false,
      };
    },

    // Update profile data
    [ProfileActions.get_profile_associated_companies_request]: (state, { payload: { user_id }}) => {
      return { ...state,
        profile_companies_loading: true,
      };
    },
    [ProfileActions.get_profile_associated_companies_success]: (state, { payload: { results, pagination } }) => {

      return { ...state,
        profile_companies_pagination: pagination,
        profile_companies_loading: false,
        profile_companies: results,
      };
    },
    [ProfileActions.get_profile_associated_companies_failure]: (state) => {

      return { ...state,
        profile_companies_loading: false,
      };
    },

    // Update profile data
    [AccountActions.put_user_success]: (state, { payload: { data, user_id } }) => {
      const profile = state.profile;
      let payload = {};

      if (profile && profile.id === user_id) {
        payload = { ...data };
      }

      return { ...state,
        profile: {
          ...profile,
          ...payload,
        },
      };
    },

    // Get profile
    [ProfileActions.get_profile_request]: (state) => {
      return {
        ...state,
        profile_loading: true,
      };
    },
    [ProfileActions.get_profile_success]: (state, { payload: { profile = {}, isFake } }) => {
      const profile_companies = state.profile_companies;

      return {
        ...state,
        profile_companies: isFake && profile.id !== state.profile.id ? [] : profile_companies,
        profile_loading: false,
        profile,
      };
    },
    [ProfileActions.get_profile_failure]: (state, { payload }) => {
      return {
        ...state,
        profile_loading: false,
      };
    },
    
    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default reducer;
