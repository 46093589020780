import * as SendersActions from '../actions/senders';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Senders {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Get sender notices
   * @param {*} param0
   */
  getSenderNotices({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    order_by = '-id',
    sender_id,
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };
    const query = `?limit=${per_page}&offset=${(page - 1) * per_page}&sender__id=${sender_id}&order_by=${order_by}`;

    
    return (dispatch, getState, { axios }) => {
      dispatch(SendersActions.get_sender_notices_request({ page, per_page }));

      return axios
        .get(`${API_URL}/notices/${query}`, authorization)
        .then((response) => {
          dispatch(SendersActions.get_sender_notices_success({
            results: response.data.results,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            },
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(SendersActions.get_sender_notices_failure({}));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Delete sender
   * @param {*} param0
   */
  deleteSender({
    resolve = noop,
    reject = noop,
    headers = {},
    sender_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(SendersActions.delete_sender_request({}));

      return axios
        .delete(`${API_URL}/notices/contacts/${sender_id}/`, autorization)
        .then((response) => {
          dispatch(SendersActions.delete_sender_success({
            sender_id,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(SendersActions.delete_sender_failure({}));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Update sender
   * @param {*} param0
   */
  updateSender({
    resolve = noop,
    reject = noop,
    headers = {},
    sender_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(SendersActions.update_sender_request({}));

      return axios
        .put(`${API_URL}/notices/contacts/${sender_id}/`, data, autorization)
        .then((response) => {
          dispatch(SendersActions.update_sender_success({
            sender: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(SendersActions.update_sender_failure({}));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get sender
   * @param sender_id
   */
  getSender({
    resolve = noop,
    reject = noop,
    headers = {},
    sender_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(SendersActions.get_sender_request({}));

      return axios
        .get(`${API_URL}/notices/contacts/${sender_id}/`, autorization)
        .then((response) => {
          dispatch(SendersActions.get_sender_success({
            sender: response.data,
            sender_not_found: false
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(SendersActions.get_sender_failure({
            sender_not_found: true
          }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get senders
   */
  getSenders({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if (search) { query += `&search=${search}`; }

    return (dispatch, getState, { axios }) => {
      dispatch(SendersActions.get_senders_request({ page, per_page }));

      return axios
        .get(`${API_URL}/notices/contacts/${query}`, autorization)
        .then((response) => {
          dispatch(SendersActions.get_senders_success({
            results: response.data.results,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            },
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(SendersActions.get_senders_failure({}));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new Senders();
