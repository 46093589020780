import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducers';
import thunk from 'redux-thunk';
import { axiosApiInstance } from './axios';

const enhancers = compose(
  applyMiddleware(thunk.withExtraArgument({ axios: axiosApiInstance })),
  process.env.NODE_ENV === 'development' &&
  typeof window !== 'undefined' &&
  typeof window.devToolsExtension !== 'undefined'
    ? // Call the brower extension function to create the enhancer.
      window.devToolsExtension()
    : // Else we return a no-op function.
      f => f,
);

const store = createStore(reducer, enhancers);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers').default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;

