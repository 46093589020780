import * as HistoriesActions from './../../actions/history';
import * as AccountActions from './../../actions/account';
import { handleActions } from 'redux-actions';

const initialState = {
  histories_loading: false,
  histories_loaded: false,
  histories_pagination: {},
  histories: [],

  snapshot_representatives_loading: false,
  snapshot_representatives_loaded: false,
  snapshot_representatives_pagination: {},
  snapshot_representatives: [],

  // Snapshot info
  snapshot_loading: false,
  snapshot_loaded: false,
  snapshot: {},

  // Event notes
  event_create_loading: false,
  event_notes_loading: false,
  event_notes_pagination: {},
  event_notes_loaded: false,
  event_notes: [],
};

// Reducer
const reducer = handleActions(
  {
    // Add event notes
    [HistoriesActions.add_event_note_request]: (state, { payload: { type } }) => {
      return type === 'event' ? { ...state,
        event_create_loading: true,
      } : {
        ...state,
      };
    },
    [HistoriesActions.add_event_note_success]: (state, { payload: { note={}, type } }) => {
      const pagination = state.event_notes_pagination;
      const event_notes = state.event_notes;

      return type === 'event' ? { ...state,
        event_notes: event_notes.concat([note]),
        event_notes_pagination: {
          ...pagination,
          max_page: Math.ceil(pagination.count + 1/pagination.per_page),
          count: pagination.count + 1,
        },
        event_create_loading: false,
      } : {
        ...state,
      };
    },
    [HistoriesActions.add_event_note_failure]: (state, { payload: { type } }) => {
      return type === 'event' ? { ...state,
        event_create_loading: false,
      } : {
        ...state,
      };
    },

    // Get event notes
    [HistoriesActions.get_event_notes_request]: (state, { payload: { type } }) => {
      return type === 'event' ? { ...state,
        event_notes_loading: true,
      } : {
        ...state,
      };
    },
    [HistoriesActions.get_event_notes_success]: (state, { payload: { pagination, results=[], type } }) => {
      return type === 'event' ? { ...state,
        event_notes_pagination: pagination,
        event_notes_loading: false,
        event_notes_loaded: true,
        event_notes: results,
      } : {
        ...state,
      };
    },
    [HistoriesActions.get_event_notes_failure]: (state, { payload: { type } }) => {
      return type === 'event' ? { ...state,
        event_notes_loading: false,
      } : {
        ...state,
      };
    },

    //Add snapshot
    [HistoriesActions.add_snapshot]: (state, { payload: { snapshot } }) => {
      return { ...state,
        snapshot
      };
    },

    // Clear history
    [HistoriesActions.clear_history]: (state, { payload: { histories = [], histories_pagination = {} } }) => {
      return { ...state,
        histories_pagination,
        histories
      };
    },

    // Get snapshot
    [HistoriesActions.get_snapshot_request]: (state) => {
      return { ...state,
        snapshot_loading: true
      };
    },
    [HistoriesActions.get_snapshot_success]: (state, { payload: { snapshot } }) => {
      return { ...state,
        snapshot_loading: false,
        snapshot_loaded: true,
        snapshot
      };
    },
    [HistoriesActions.get_snapshot_failure]: (state) => {
      return { ...state,
        snapshot_loading: false
      };
    },

    // Get event representatives
    [HistoriesActions.get_event_representatives_request]: (state, { payload }) => {
      return { ...state,
        snapshot_representatives_loading: true
      };
    },
    [HistoriesActions.get_event_representatives_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        snapshot_representatives_pagination: pagination,
        snapshot_representatives_loading: false,
        snapshot_representatives_loaded: true,
        snapshot_representatives: results || []
      };
    },
    [HistoriesActions.get_event_representatives_failure]: (state, { payload }) => {
      return { ...state,
        snapshot_representatives_loading: false
      };
    },

    // Get histories
    [HistoriesActions.get_histories_request]: (state, { payload }) => {
      return { ...state,
        histories_loading: true
      };
    },
    [HistoriesActions.get_histories_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        histories_pagination: pagination,
        histories_loading: false,
        histories_loaded: true,
        histories: results
      };
    },
    [HistoriesActions.get_histories_failure]: (state, { payload }) => {
      return { ...state,
        histories_loading: false
      };
    },
    
    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
  },
  initialState,
);

export default reducer;
