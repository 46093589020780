import PropTypes from 'prop-types';
import React from 'react';

const GeneratorId = ({ id, length, className, elemType=null }) => {
  const lengthId = id.toString().length;
  const totalNumberOfZero = length - lengthId;
  let outputId = '';

  if (totalNumberOfZero === 1) {
    if(elemType) {
      outputId = `ord_0${id}`;
    } else {
      outputId = `0${ id }`;
    }
  } else if (totalNumberOfZero === 2) {
    outputId = `00${ id }`;
  } else if (totalNumberOfZero === 3) {
    outputId = `000${ id }`;
  } else if (totalNumberOfZero === 4) {
    outputId = `0000${ id }`;
  } else {
    outputId = `${ id }`;
  }

  return <span className={ className }>{ outputId }</span>;
};

GeneratorId.propTypes = {
  className: PropTypes.string,
  length: PropTypes.number,
  id: PropTypes.number,
};

GeneratorId.defaultProps = {
  id: 0,
  length: 4
};

export default GeneratorId;
