import * as AccountActions from './../../actions/account';
import * as UsersActions from './../../actions/users';
import { handleActions } from 'redux-actions';

const initialState = {
  users_loading: false,
  users_loaded: false,
  users_pagination: {},
  users: [],
  user_statuses: [],
  user_statuses_loading: false,
  user_statuses_loaded: false,
  user_statuses_pagination: {},

};

// Reducer
const reducer = handleActions(
  {
    // Delete user
    [UsersActions.delete_user_success]: (state, { payload: { user_id } }) => {
      return { ...state,
        users: state.users.filter((item) => item.id !== user_id),
      };
    },

    // Get company info
    [UsersActions.get_users_request]: (state) => {
      return { ...state,
        users_loading: true
      };
    },
    [UsersActions.get_users_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        users_pagination: pagination,
        users_loading: false,
        users_loaded: true,
        users: results
      };
    },
    [UsersActions.get_users_failure]: (state) => {
      return { ...state,
        users_loading: false
      };
    },
    // Logout
    [AccountActions.logout_request]: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
    [UsersActions.user_statuses_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        user_statuses: pagination.page > 1 ? state.user_statuses.concat(results) : results,
        user_statuses_pagination: pagination,
        user_statuses_loaded: false,
      };
    },
    [UsersActions.user_statuses_failure]: (state) => {
      return { ...state,
        user_statuses_loading: false
      };
    },
  },
  initialState,
);

export default reducer;
