import { createAction } from 'redux-actions';

export const get_filters_success = createAction('GET_FILTERS_LIST_SUCCESS');
export const get_filters_request = createAction('GET_FILTERS_REQUEST');

export const get_filter_success = createAction('GET_FILTER_REQUEST');

export const get_conditions_request = createAction('GET_CONDITIONS_REQUEST');
export const get_conditions_success = createAction('GET_CONDITIONS_SUCCESS');

export const get_fields_request = createAction('GET_FIELDS_REQUEST');
export const get_fields_success = createAction('GET_FIELDS_SUCCESS');

export const get_field_conditions_request = createAction('GET_FIELD_CONDITIONS_REQUEST');
export const get_field_conditions_success = createAction('GET_FIELD_CONDITIONS_SUCCESS');


export const get_filters_actions_success = createAction('GET_FILTERS_LIST_SUCCESS');
export const get_filters_actions_request = createAction('GET_FILTERS_REQUEST');