import { createAction } from 'redux-actions';

export const get_senders_request = createAction('GET_SENDERS_REQUEST');
export const get_senders_success = createAction('GET_SENDERS_SUCCESS');
export const get_senders_failure = createAction('GET_SENDERS_FAILURE');

export const get_sender_request = createAction('GET_SENDER_REQUEST');
export const get_sender_success = createAction('GET_SENDER_SUCCESS');
export const get_sender_failure = createAction('GET_SENDER_FAILURE');

export const update_sender_request = createAction('UPDATE_SENDER_REQUEST');
export const update_sender_success = createAction('UPDATE_SENDER_SUCCESS');
export const update_sender_failure = createAction('UPDATE_SENDER_FAILURE');

export const delete_sender_request = createAction('DELETE_SENDER_REQUEST');
export const delete_sender_success = createAction('DELETE_SENDER_SUCCESS');
export const delete_sender_failure = createAction('DELETE_SENDER_FAILURE');

export const get_sender_notices_request = createAction('GET_SENDER_NOTICES_REQUEST');
export const get_sender_notices_success = createAction('GET_SENDER_NOTICES_SUCCESS');
export const get_sender_notices_failure = createAction('GET_SENDER_NOTICES_FAILURE');
