import PaymentMethodContainerItem from '../../PaymentMethodContainerItem/PaymentMethodContainerItem';
import CreditCardForm from '../../../forms/CreditCardForm';
import { withRouter } from 'react-router-dom';
import React, { PureComponent } from 'react';
import { Payment } from '../../../services';
import { noop } from '../../../utils/utils';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

class PaymentTypeContainer extends PureComponent {

  render() {
    const { activeType, continueWithoutPayment, loading, btnText } = this.props;
    
    return (
      <div className="payment-method-wrapper">
        { activeType === 'credit-card' &&
          <CreditCardForm 
            continueWithoutPayment={ continueWithoutPayment }
            onSubmit={ this.props.onSubmit.bind(null, 1)} 
            errorVisible={this.props.errorVisible}
            onCloseError={this.props.onCloseError}
            errorText={this.props.errorText}
            loading={ loading }
            btnText={btnText}
          />
        }

        { activeType === 'paypal' &&
          <PaymentMethodContainerItem 
            onContinue={ this.props.onSubmit.bind(null, 2)} 
            title={'Paypal'}
            imgUrl={'/img/paypal-icon-big.svg'}
            renderBody={ () => {
              return(
                <div className="content">
                  <p>We can accept manual payments via PayPal. Soon, we will have them automated.</p>
                  <p>
                    Press "Continue" to finish the order. Here is what is going to happen:
                  </p>
                  <p>
                    1) Our team member will start working on your order. By the way, this person will become your representative.<br />
                    2) You will receive an invoice from us, which will have payment instructions.<br />
                    3) We will have a huge party in your honor!
                  </p>
                  <p>
                    To pay via PayPal, please send payment to <a href="#">billing@dmcanow.io</a>
                  </p>
                  <p>
                    Don't forget to mention the invoice number in payment details.
                  </p>
                </div>
              )
            }}
            loading={ loading }
            btnText={btnText}
          />
        }

        { activeType === 'paxum' &&
          <PaymentMethodContainerItem 
            onContinue={ this.props.onSubmit.bind(null, 3)} 
            title={'Paxum'}
            imgUrl={'/img/paxum-icon-big.svg'}
            renderBody={ () => {
              return(
                <div className="content">
                  <p>We can accept manual payments via Paxum. Soon, we will have them automated.</p>
                  <p>
                    Press "Continue" to finish the order. Here is what is going to happen:
                  </p>
                  <p>
                    1) Our team member will start working on your order. By the way, this person will become your representative.<br />
                    2) You will receive an invoice from us, which will have payment instructions.<br />
                    3) We will have a huge party in your honor!
                  </p>
                  <p>
                    To pay via Paxum, please send payment to <a href="#">billing@dmcanow.io</a>
                  </p>
                  <p>
                    Don't forget to mention the invoice number in payment details.
                  </p>
                </div>
              )
            }}
            loading={ loading }
            btnText={btnText}
          />
        }
{/* 
        { activeType === 'e-payments' &&
          <PaymentMethodContainerItem 
            onContinue={ this.props.onSubmit.bind(null, 4)} 
            title={'ePayments'}
            imgUrl={'/img/epayment-icon-big.svg'}
            renderBody={ () => {
              return(
                <div className="content">
                  <p>We can accept manual payments via ePayments. Soon, we will have them automated.</p>
                  <p>
                    Press "Continue" to finish the order. Here is what is going to happen:
                  </p>
                  <p>
                    1) Our team member will start working on your order. By the way, this person will become your representative.<br />
                    2) You will receive an invoice from us, which will have payment instructions.<br />
                    3) We will have a huge party in your honor!
                  </p>
                  <p>
                    To pay via ePayments, please send payment to this wallet: 000-360926.
                  </p>
                  <p>
                    Don't forget to mention the invoice number in payment details. 
                  </p>
                </div>
              )
            }}
            loading={ loading }
            btnText={btnText}
          />
        } */}

        { activeType === 'ach' &&
          <PaymentMethodContainerItem 
            onContinue={ this.props.onSubmit.bind(null, 5)} 
            title={'ACH Payment'}
            imgUrl={'/img/ach-icon-big.svg'}
            renderBody={ () => {
              return(
                <div className="content">
                  <p>We can accept manual payments via ACH (US only). Soon, we will have them automated.</p>
                  <p>
                    Press "Continue" to finish the order. Here is what is going to happen:
                  </p>
                  <p>
                    1) Our team member will start working on your order. By the way, this person will become your representative.<br />
                    2) You will receive an invoice from us, which will have payment instructions.<br />
                    3) We will have a huge party in your honor!
                  </p>
                  <p>
                    Even though invoice will have ACH payment instructions, we will email them to you as well.
                  </p>
                </div>
              )
            }}
            loading={ loading }
            btnText={btnText}
          />
        }

        { activeType === 'wire-payment' &&
          <PaymentMethodContainerItem 
            onContinue={ this.props.onSubmit.bind(null, 6)} 
            title={'Wire Payment'}
            imgUrl={'/img/wire-payment-icon-big.svg'}
            renderBody={ () => {
              return(
                <div className="content">
                  <p>We can accept Wire payments.</p>
                  <p>
                    Press "Continue" to finish the order. Here is what is going to happen:
                  </p>
                  <p>
                    1) Our team member will start working on your order. By the way, this person will become your representative.<br />
                    2) You will receive an invoice from us, which will have payment instructions.<br />
                    3) We will have a huge party in your honor!
                  </p>
                  <p>
                    Even though invoice will have Wire payment instructions, we will email them to you as well.
                  </p>
                </div>
              )
            }}
            loading={ loading }
            btnText={btnText}
          />
        }

        { activeType === 'crypto' &&
          <PaymentMethodContainerItem 
            onContinue={ this.props.onSubmit.bind(null, 7)} 
            title={'Crypto'}
            imgUrl={'/img/crypto-icon-big.svg'}
            renderBody={ () => {
              return(
                <div className="content">
                  <p>
                    Will all future transaction happen via blockchain? We shall see... 
                    Meanwhile, we can accept manual payments via BTC, BCH, ETH, ETC, LTC, ZRX, BAT, USDC, ZEC, XRP, XLM. 
                    Soon, we will have them automated.
                  </p>
                  <p>
                    Press "Continue" to finish the order. Here is what is going to happen:
                  </p>
                  <p>
                    1) Our team member will start working on your order. By the way, this person will become your representative.<br />
                    2) You will receive an invoice from us, which will have payment instructions.<br />
                    3) We will have a huge party in your honor!
                  </p>
                  <p>
                    You will receive an email with crypto wallet address. Please let us know when you will send the coins.
                  </p>
                </div>
              )
            }}
            loading={ loading }
            btnText={btnText}
          />
        }
      </div>
    )
  }
}

PaymentTypeContainer.propTypes = {
  continueWithoutPayment: PropTypes.bool,
  onCloseError: PropTypes.func,
  activeType: PropTypes.string,
  btnText: PropTypes.string,
  onSubmit: PropTypes.func,
  type: PropTypes.string,
};

PaymentTypeContainer.defaultProps = {
  continueWithoutPayment: false,
  btnText: 'Continue',
  onCloseError: noop,
  onSubmit: noop,  
};

export default compose(
  withRouter,
  withCookies,
  connect((state) => {
    return {
      createPayment: Payment.createPayment,
      account: state.account
    };
  }, {
    dispatch: (action) => {
      return (dispatch) => {
        dispatch(action);
      }
    }
  }),
)(PaymentTypeContainer);
