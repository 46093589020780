import * as DomainsActions from '../actions/domains';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Domains {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Update domain
   * @param {*} param0
   */
  updateDomain({
    resolve = noop,
    reject = noop,
    headers = {},
    domain_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(DomainsActions.update_domain_request({ domain_id, data }));

      return axios
        .patch(`${API_URL}/domains/${domain_id}/`, data, autorization)
        .then((response) => {
          dispatch(DomainsActions.update_domain_success({ domain_id, data }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          dispatch(DomainsActions.update_domain_failure({ domain_id, data }));
          reject(error);
        });
    };
  }
  /**
   * Add domain
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  addDomain({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/domains/create/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Check if domain it's anvalable
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  availableDomain({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/domains/is-busy/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Delete domain
   * @param resolve
   * @param reject
   * @param headers
   * @param domain_id
   * @returns {function(*, *, {axios: *})}
   */
  deleteDomain({
    resolve = noop,
    reject = noop,
    headers = {},
    domain_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      return axios
        .delete(`${API_URL}/domains/${domain_id}/`, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get domain histories
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param domain_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getDomainHistories({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    domain_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&object_id=${domain_id}&content_type__model=domain`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(DomainsActions.get_domain_histories_request({ page, per_page }));
      }
      return axios
        .get(`${API_URL}/history/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(DomainsActions.get_domain_histories_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          if (dispatchAction) {
            dispatch(DomainsActions.get_domain_histories_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get domain notices
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param domain_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getDomainNotices({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    domain_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&domains__domain__id=${domain_id}`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(DomainsActions.get_domain_notices_request({ page, per_page }));
      }
      return axios
        .get(`${API_URL}/notices/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(DomainsActions.get_domain_notices_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(DomainsActions.get_domain_notices_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get domain orders
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param domain_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getDomainOrders({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    domain_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&domains__domain__id=${domain_id}`;

    return (dispatch, getState, { axios }) => {
      dispatch(DomainsActions.get_domain_orders_request({ page, per_page }));
      return axios
        .get(`${API_URL}/orders/${query}`, autorization)
        .then((response) => {
          const pagination = {
            max_page: Math.ceil(response.data.count / per_page),
            count: response.data.count,
            per_page,
            page,
          };

          dispatch(DomainsActions.get_domain_orders_success({
            results: response.data.results,
            pagination,
          }));

          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(DomainsActions.get_domain_orders_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get domain by id
   * @param resolve
   * @param reject
   * @param headers
   * @param domain_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getDomain({
    resolve = noop,
    reject = noop,
    headers = {},
    domain_id,
    dispatchAction = true,
    customError = null,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) { dispatch(DomainsActions.get_domain_request({ })); }
      return axios
        .get(`${API_URL}/domains/${domain_id}/`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(DomainsActions.get_domain_success({
              domain: response.data,
              domain_error: null,
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          const response = error.response || {};

          if (dispatchAction) { dispatch(DomainsActions.get_domain_failure({
            domain_not_found: true,
            domain_error: {
              status: response.status,
              data: response.data,
            },
          })); }

          if (!customError && response.data && response.data.detail) {
            customError = response.data.detail;
          }

          Logger.logResponseError(error, customError);
          reject(error);
        });
    };
  }

  /**
   * Get Domains
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param search
   * @param dispatchAction
   * @param params
   * @returns {function(*, *, {axios: *})}
   */
  getDomains({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    search,
    dispatchAction = true,
    params = {},
    company_id = null,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    if (search) { query += `&search=${search}`; }

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(DomainsActions.get_domains_request({ search, page, per_page }));
      }
      return axios
        .get(`${API_URL}/domains/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(DomainsActions.get_domains_success({
              search, page, per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(DomainsActions.get_domains_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get company event domains
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param page
   * @param per_page
   * @param search
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyEventDomains({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    page = 1,
    search,
    per_page = 10,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}${search ? `&search=${search}` : ''}`;
    if (company_id) {
      query += `&company__id=${company_id}`;
    }

    return (dispatch, getState, { axios }) => {
      dispatch(DomainsActions.get_create_events_domains_request({ company_domains_id: company_id }));
      return axios
        .get(`${API_URL}/domains/${query}`, autorization)
        .then((response) => {
          dispatch(
            DomainsActions.get_create_events_domains_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(DomainsActions.get_create_events_domains_failure({}));

          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get amendment company domains
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param page
   * @param per_page
   * @returns {function(*, *, {axios: *})}
   */
  getAmendmentCompanyDomains({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    page = 1,
    per_page = 10,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&company__id=${company_id}&status__name=Registered`;

    return (dispatch, getState, { axios }) => {
      dispatch(DomainsActions.get_amendment_company_domains_request({ amendment_company_domains_id: company_id }));
      return axios
        .get(`${API_URL}/domains/${query}`, autorization)
        .then((response) => {
          dispatch(
            DomainsActions.get_amendment_company_domains_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            DomainsActions.get_amendment_company_domains_failure({
              amendment_company_domains_loaded: true,
              amendment_company_domains_loading: false,
              amendment_company_domains_id: company_id,
              amendment_company_domains: [],
            }),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get company domains
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param load_more
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyDomains({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    load_more = false,
    page = 1,
    per_page = 10,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&company__id=${company_id}`;
    return (dispatch, getState, { axios }) => {
      dispatch(DomainsActions.get_company_domains_request({ load_more, company_domains_id: company_id }));
      return axios
        .get(`${API_URL}/domains/${query}`, autorization)
        .then((response) => {
          dispatch(
            DomainsActions.get_company_domains_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            DomainsActions.get_company_domains_failure({
              company_domains_loaded: true,
              company_domains_loading: false,
              company_domains_id: company_id,
              company_domains: [],
            }),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  downloadDomainsRequest({
    resolve = noop,
    reject = noop,
    headers = {},
    typ,
    order_id,
    load_more = false,
    page = 1,
    per_page = 10,
  }) {
    return (dispatch, getState, { axios }) => {
      return axios({
        method: 'POST',
        url: `${API_URL}/orders/${order_id}/download-domains/?`,
        responseType: 'blob',
        headers: {
          Authorization: `JWT ${headers.token}`,
        },
        data: {
          is_removed: typ,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `domains_order_O${order_id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        resolve(response);
      })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Upload domains feature
   * @param {*} param0
   * @returns
   */
  uploadDomains({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = {
      headers: { Authorization: `JWT ${headers.token}` },
      'Content-Type': 'multipart/form-data',
    };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/domains/upload/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new Domains();
