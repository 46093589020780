import * as FiltersActions from './../../actions/filters';
import { handleActions } from 'redux-actions';

const initialState = {
  filters: [],
  filter_pagination: {},
  admin_filters_loading: false,
  field_conditions: [],
  // Domain info
  filter: {
    notices: [],
    fields: [],
  },
  fitler_actions: [],
  conditions: [],
};

// Reducer
const reducer = handleActions(
  {
    [FiltersActions.get_filters_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        filter_pagination: pagination,
        admin_filters_loading: false,
        filters: results
      };
    },
    [FiltersActions.get_filters_request]: (state, { payload }) => {
      return { ...state,
        admin_filters_loading: true,
        ...payload
      };
    },
    [FiltersActions.get_filter_success]: (state, { payload: { filter }}) => {
      return { ...state,
        filter: filter,
      }
    },
    [FiltersActions.get_conditions_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        conditions: results,
      }
    },
    [FiltersActions.get_fields_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        fields: results,
      }
    },
    [FiltersActions.get_field_conditions_success]: (state, { payload: { pagination, results } }) => {
      return { ...state,
        field_conditions: results,
      }
    },
  },
  initialState,
);

export default reducer;
