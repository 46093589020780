import * as CompaniesActions from '../actions/companies';
import * as PaymentActions from '../actions/payment';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Payment {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Create payment
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  createPayment({
    resolve = noop,
    reject = noop,
    data = {},
    headers = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(PaymentActions.create_payment_request(data));
      return axios
        .post(`${API_URL}/orders/payment/`, data, autorization)
        .then((response) => {
          resolve(response.data, dispatch);
          dispatch(PaymentActions.create_payment_success({
            ...data,
          }));
        })
        .catch((error) => {
          reject(error, dispatch);
          dispatch(PaymentActions.create_payment_failure(error));
          Logger.logResponseError(error);
        });
    };
  }
}

export default new Payment();
