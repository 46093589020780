import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';

import histories from './modules/histories';
import companies from './modules/companies';
import account from './modules/account';
import domains from './modules/domains';
import notices from './modules/notices';
import profile from './modules/profile';
import senders from './modules/senders';
import orders from './modules/orders';
import users from './modules/users';
import filters from './modules/filters';
import ignored_links from './modules/ignored_links';

export default combineReducers({
  form: formReducer,
  companies,
  histories,
  profile,
  domains,
  account,
  notices,
  senders,
  orders,
  users,
  filters,
  ignored_links,
});
