import { validateNewCompanyForm } from '../utils/validators';
import { filter, closeDropdown, noop } from '../utils/utils';
import { Field, reduxForm, change } from 'redux-form';
import AsyncSelect from 'react-select/lib/Async';
import React, { PureComponent } from 'react';
import { renderField, dropdownError } from './form-elements';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Notices } from '../services';
import { Auth } from '../services';
import PropTypes from 'prop-types';
import { compose } from 'redux';

class AddNewCompanyForm extends PureComponent {
  /**
   * Class constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.loadCountries = this.loadCountries.bind(this);
    this.onChange = this.onChange.bind(this);
    let country = {};

    if (props.initialValues && props.initialValues.country) {
      country.label = props.initialValues.country;
      country.value = props.initialValues.country;
    }

    this.state = {
      country,
    };
  }

  /**
   * Load countries
   * @param inputValue
   * @returns {Promise}
   */
  loadCountries(inputValue) {
    const { getCountries, cookies } = this.props;

    return new Promise(resolve => {
      getCountries({
        headers: {
          token: localStorage.getItem('token')
        },
        per_page: 251,
        page: 1,
        resolve: (response) => {
          let countries = response.data.results.map((country) => ({
            value: `${ country.en }`,
            label: `${ country.en }`,
          }));
          resolve(filter(inputValue, countries));
        },
        reject: (error) => {
          resolve(filter(inputValue, []));
        }
      });
    });
  }

  /**
   * On company change value
   * @param input
   * @param option
   */
  onChange(action, option) {
    if (!option) { return; }

    if (Array.isArray(option) && !option.length) {
      this.props.dispatch(change('add-new-company', action, null));

      if (this.props.submitFailed) {
        this.props.touch([action]);
      }
    } else {
      this.props.dispatch(change('add-new-company', action, option.value));
    }
  }

  componentDidMount() {
    const select = document.getElementById('new_company_country');
    if (select) {
      select.setAttribute('data-1p-ignore', 'true')
    }
  }

  render() {
    const { handleSubmit, onSubmit, loading, errors, initialValues } = this.props;

    return (
      <form className="new-company-step-1" onSubmit={ handleSubmit(onSubmit) }>
        <div className="new-company-input-block">
          <div className="form-group">
            <label htmlFor="name">Company (Or individual) name</label>
            <div className="input-field">
              <Field
                className="form-control form-control-lg"
                component={renderField}
                placeholder=""
                type="text"
                name="name"
                id="name"
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="line1">Street</label>
            <div className="input-field">
              <Field
                className="form-control form-control-lg"
                component={ renderField }
                placeholder=""
                name="address"
                type="text"
                id="line1"
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="city">City</label>
            <div className="input-field">
              <Field
                className="form-control form-control-lg"
                component={ renderField }
                placeholder=""
                name="city"
                type="text"
                id="city"
              />
            </div>
          </div>

          <div className="new-company-input-block-bottom">
            <div className="form-group">
              <label htmlFor="state">state / province</label>
              <div className="input-field">
                <Field
                  className="form-control form-control-lg"
                  component={ renderField }
                  placeholder=""
                  name="state"
                  type="text"
                  id="state"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="zip">zip / post code</label>
              <div className="input-field">
                <Field
                  className="form-control form-control-lg"
                  component={ renderField }
                  placeholder=""
                  name="zip"
                  type="text"
                  id="zip"
                />
              </div>
            </div>
          </div>
          <div className="form-group with-icon">
            <label htmlFor="new_company_country">Country</label>
            <div className={`input-field ${ errors && errors.country && this.props.submitFailed ? 'is-invalid' : ''}`}>
              <AsyncSelect
                noOptionsMessage={() => 'Start typing to see suggestions'}
                defaultInputValue={ initialValues.country }
                onMenuClose={ closeDropdown.bind(null, this.country) }
                onChange={ this.onChange.bind(null, 'country') }
                ref={(node) => this.country = node }
                className="react-select-container menu-dropup"
                defaultOptions
                loadOptions={ this.loadCountries }
                classNamePrefix="react-select"
                openMenuOnFocus={ true }
                inputId="new_company_country"
                cacheOptions
                placeholder=""
              />
              <Field component={ dropdownError } name="country" type="hidden" />
            </div>
          </div>
          <button className={`btn btn-green btn-lg btn-db arrow-btn ${ loading ? 'loading' : ''}`} type="submit">
            Next
            <i className="icons white-arrow"></i>
          </button>
        </div>
        <div className="extra-info-block">
          <div className="extra-item">
            <span className="title">Please keep in mind</span>
            <p>
              DMCA Agent public registration is for companies (or individuals) who operate service (domains).
              Please make sure you provide complete company name.
               The same way it is written on company registration documents.
            </p>
            <p>
              If you don’t have a company and would like to register an individual person as the operator of services,
              please provide person’s full name as reflected on government-issued ID.
            </p>
          </div>
        </div>
      </form>
    );
  }
}

AddNewCompanyForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

AddNewCompanyForm.defaultProps = {
  onSubmit: noop,
  loading: false,
};

export default compose(
  withCookies,
  reduxForm({
    validate: validateNewCompanyForm,
    form: 'add-new-company',
    initialValues: {
      form: {},
      status: null
    }
  }),
  connect(
    (state) => {
      return {
        errors: state.form['add-new-company'].syncErrors
      };
    },
    {
      login: Auth.isAuth,
      getCountries: Notices.getCountries,
      dispatch: (action) => {
        return (dispatch) => dispatch(action);
      }
    },
  ),
)(AddNewCompanyForm);
