/* eslint-disable */
import * as CompaniesActions from '../actions/companies';
import * as NoticesActions from '../actions/notices';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class Companies {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Remove company
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param data
   * @returns {function(*, *, {axios: *}): Promise<any> | Function | any | undefined | Observable<any>}
   */
  removeCompany({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    data = { },
  }) {
    return (dispatch, getState, { axios }) => axios
      .delete(`${API_URL}/companies/${company_id}/removal/`, {
        headers: { Authorization: `JWT ${headers.token}` },
        data,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        Logger.logResponseError(error);
        reject(error);
      });
  }

  /**
   * Get company statuses
   * @param {*} param0
   */
  getCompanyStatuses({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 50,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => axios
      .get(`${API_URL}/companies/statuses/`, autorization)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        Logger.logResponseError(error);
        reject(error);
      });
  }

  /**
   * Update company billing info
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  updateCompanyBillingInfo({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    data = {},
  }) {
    const authorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(CompaniesActions.update_company_billing_info_request({}));
      return axios
        .put(`${API_URL}/companies/${company_id}/billing-info/`, data, authorization)
        .then((response) => {
          dispatch(
            CompaniesActions.update_company_billing_info_success({
              company_id,
              data,
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            CompaniesActions.update_company_billing_info_failure({
              company_id,
              data,
            }),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get company representatives
   * @param resolve
   * @param reject
   * @param company_id
   * @param headers
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyRepresentative({
    resolve = noop,
    reject = noop,
    company_id,
    headers = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => axios
      .get(`${API_URL}/representative/${company_id}/`, autorization)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        Logger.logResponseError(error);
        reject(error);
      });
  }

  /**
   * Get types of list
   * @param resolve
   * @param reject
   * @param headers
   * @returns {function(*, *, {axios: *})}
   */
  getTypesList({
    resolve = noop,
    reject = noop,
    headers = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => axios
      .get(`${API_URL}/representative/types/`, autorization)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        Logger.logResponseError(error);
        reject(error);
      });
  }

  /**
   * Add company event
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  createCompany({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => axios
      .post(`${API_URL}/companies/create/`, data, autorization)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        Logger.logResponseError(error);
        reject(error);
      });
  }

  /**
   * Add company event
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  addCompanyEvent({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => axios
      .post(`${API_URL}/companies/${company_id}/event/create/`, data, autorization)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        Logger.logResponseError(error);
        reject(error);
      });
  }

  /**
   * Get company billing info
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyBillingInfo({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      dispatch(CompaniesActions.get_company_billing_info_request({ company_billing: {} }));
      return axios
        .get(`${API_URL}/companies/${company_id}/billing-info/`, autorization)
        .then((response) => {
          dispatch(
            CompaniesActions.get_company_billing_info_success({
              company_billing: response.data,
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            CompaniesActions.get_company_billing_info_failure({}),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get company orders
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param company_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyOrders({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    company_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&company=${company_id}`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(CompaniesActions.get_company_orders_request({ page, per_page }));
      }

      return axios
        .get(`${API_URL}/orders/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(CompaniesActions.get_company_orders_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(CompaniesActions.get_company_orders_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get Company events
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param company_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyEvents({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    company_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&object_id=${company_id}&content_type__model=company&is_snapshot=false`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(CompaniesActions.get_company_events_request({ page, per_page }));
      }
      return axios
        .get(`${API_URL}/history/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(CompaniesActions.get_company_events_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(CompaniesActions.get_company_events_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get Company notices
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param company_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyNotices({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    company_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&company__id=${company_id}`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(NoticesActions.get_company_notices_request({ page, per_page }));
      }
      return axios
        .get(`${API_URL}/notices/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(NoticesActions.get_company_notices_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(NoticesActions.get_company_notices_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Update company
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  updateCompany({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(CompaniesActions.update_company_request({}));
      return axios
        .patch(`${API_URL}/companies/${company_id}/`, data, autorization)
        .then((response) => {
          dispatch(
            CompaniesActions.update_company_success({
              company_id,
              data,
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            CompaniesActions.update_company_failure({
              company_id,
            }),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get amendment company
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @returns {function(*, *, {axios: *})}
   */
  getAmendmentCompany({
    reject = noop,
    resolve = noop,
    headers = {},
    company_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    return (dispatch, getState, { axios }) => {
      dispatch(CompaniesActions.get_amendment_company_request({ }));
      return axios
        .get(`${API_URL}/companies/${company_id}/`, autorization)
        .then((response) => {
          dispatch(
            CompaniesActions.get_amendment_company_success({
              amendment_company: response.data,
              amendment_company_id: company_id,
              amendment_company_loaded: true,
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            CompaniesActions.get_amendment_company_failure({
              amendment_company_loaded: true,
            }),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get company
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @returns {function(*, *, {axios: *})}
   */
  getCompany({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(CompaniesActions.get_company_request({ }));
      return axios
        .get(`${API_URL}/companies/${company_id}/`, autorization)
        .then((response) => {
          axios.get(`${API_URL}/companies/${company_id}/representative-permissions/`, autorization).then((res1) => {
            dispatch(
              CompaniesActions.get_company_success({
                company: response.data,
                company_loaded: true,
                company_id,
                userCompanyPermissions: {
                  roles: [],
                  ...res1.data,
                },
              }),
            );
            resolve(response);
          }).catch((error) => {
            dispatch(
              CompaniesActions.get_company_failure({
                company_not_found: true,
                company_loaded: true,
                userCompanyPermissions: {
                  roles: [],
                },
              }),
            );
            Logger.logResponseError(error);
            reject(error);
          });
        })
        .catch((error) => {
          dispatch(
            CompaniesActions.get_company_failure({
              company_not_found: true,
              company_loaded: true,
              userCompanyPermissions: {
                roles: [],
              },
            }),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get companies
   * @param resolve
   * @param reject
   * @param headers
   * @param dispatchAction
   * @param per_page
   * @param page
   * @returns {function(*, *, {axios: *})}
   */
  getCompanies({
    reject = noop,
    resolve = noop,
    headers = {},
    per_page = 20,
    page = 1,
    dispatchAction = true,
    search,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(CompaniesActions.get_user_companies_request({}));
      }

      let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

      if (search) {
        query += `&search=${search}`;
      }

      return axios
        .get(`${API_URL}/companies/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(CompaniesActions.get_user_companies_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(
              CompaniesActions.get_user_companies_failure({}),
            );
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get admin companies
   * @param resolve
   * @param reject
   * @param headers
   * @param per_page
   * @param page
   * @param search
   * @param params
   * @returns {function(*, *, {axios: *}): Promise<any> | Function | any | undefined | Observable<any>}
   */
  getAdminCompanies({
    resolve = noop,
    reject = noop,
    headers = {},
    per_page = 20,
    page = 1,
    search,
    params = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(CompaniesActions.get_admin_companies_request({}));

      let query = '';

      if (per_page !== null) {
        query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;
      } else {
        query = '?limit=50';
      }

      for (let prop in params) {
        if (params[prop]) {
          query += `&${prop}=${params[prop]}`;
        }
      }

      if (search) {
        query += `&search=${search}`;
      }

      return axios
        .get(`${API_URL}/companies/${query}`, autorization)
        .then((response) => {
          dispatch(CompaniesActions.get_admin_companies_success({
            results: response.data.results,
            pagination: {
              max_page: per_page !== null ? Math.ceil(response.data.count / per_page) : null,
              count: response.data.count,
              per_page,
              page,
            },
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            CompaniesActions.get_admin_companies_failure({}),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  getCompanyInvoices({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    company_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&company=${company_id}`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(CompaniesActions.get_company_invoices_request({ page, per_page }));
      }

      return axios
        .get(`${API_URL}/companies/${company_id}/invoices/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(CompaniesActions.get_company_invoices_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(CompaniesActions.get_company_invoices_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  addCompanyNote({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(CompaniesActions.add_company_note_request({ }));
      return axios
        .post(`${API_URL}/notes/create/`, data, autorization)
        .then((response) => {
          dispatch(CompaniesActions.add_company_note_success({
            note: response.data,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(CompaniesActions.add_company_note_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  getCompanyNotes({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    company_id,
    contentType = 58,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&object_id=${company_id}&content_type__model=company`;
    return (dispatch, getState, { axios }) => {
      dispatch(CompaniesActions.get_company_notes_request({ page, per_page }));
      return axios
        .get(`${API_URL}/notes/${query}`, autorization)
        .then((response) => {
          dispatch(CompaniesActions.get_company_notes_success({
            results: response.data.results,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            },
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(CompaniesActions.get_company_notes_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new Companies();
