import React from 'react';
import InputMask from 'react-input-mask';

export const renderField = ({
  id,
  input,
  placeholder,
  disable1pass = true,
  type,
  meta: { touched, error, warning },
  refElem = () => {},
  readOnly = false,
  classname = '',
  showErrorText= true,
  maxLength = 100000,
}) => (
  <span className="input-row">
    <input
      id={ id }
      ref={ refElem }
      readOnly={ readOnly }
      { ...input }
      placeholder={placeholder}
      type={type}
      data-1p-ignore={disable1pass}
      className={`form-control form-control-lg ${ classname } ${
        touched && error && !readOnly ? 'is-invalid' : ''
      }`}
      maxLength={maxLength}
    />
    {touched && (error && !readOnly && showErrorText && <span className="invalid-feedback">{ error }</span>)}
  </span>
);

export const renderFieldInputMask = ({
  input,
  meta: { touched, error, value },
  inputMaskProps = {}
}) => {
  return (
    <span className="input-row">
      <InputMask
        { ...inputMaskProps }
        {...input}
        className={`form-control form-control-lg ${
            touched && error ? 'is-invalid' : ''
          }`
        }
        onChange={(event) => {
          if (input.onChange) {
            input.onChange(event);
          }

          if (inputMaskProps.onInputMaskChange) {
            inputMaskProps.onInputMaskChange(event);
          }
        }}
      />
      {touched && error && <span className="invalid-feedback">{ error }</span>}
    </span>
  )
};

export const renderTextareaField = ({
  id,
  input,
  placeholder,
  type,
  meta: { touched, error, warning },
  classname = '',
  rows = 2,
  refElem = () => {}
}) => (
  <span className="input-row">
    <textarea
      id={ id }
      ref={ refElem }
      {...input}
      placeholder={placeholder}
      type={type}
      className={`form-control form-control-lg ${ classname } ${
        touched && error ? 'is-invalid' : ''
      }`}
      rows={rows}
    />
    {touched && (error && <span className="invalid-feedback">{error}</span>)}
  </span>
);


export const dropdownError = ({ meta: { touched, error } }) => touched && error ?  <span className="invalid-feedback">{error}</span> : null;
