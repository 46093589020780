import * as HistoryActions from '../actions/history';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';

const API_URL = getApiUrl();

class History {

  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }



  /**
   * Get event representatives
   * @param resolve
   * @param reject
   * @param company_id
   * @param headers
   * @param dispatchAction
   * @param page
   * @param per_page
   * @returns {function(*, *, {axios: *})}
   */
  getEventRepresentative({
    resolve = noop,
    reject = noop,
    company_id,
    headers = {},
    page = 1,
    per_page = 10,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(HistoryActions.get_event_representatives_request({ }));
      }
      return axios
        .get(`${API_URL}/representative/${company_id}/?limit=${per_page}&offset=${(page - 1) * per_page}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_event_representatives_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_event_representatives_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get snapshot
   * @param resolve
   * @param reject
   * @param headers
   * @param snapshot_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getSnapshot({
    resolve = noop,
    reject = noop,
    headers = {},
    snapshot_id,
    dispatchAction = true,
    is_snapshot = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(HistoryActions.get_snapshot_request({ }));
      }
      return axios
        .get(`${API_URL}/history/?uuid=${ snapshot_id }&is_snapshot=${ is_snapshot }`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_snapshot_success({
              snapshot: response.data.results[0] || {}
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_snapshot_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   *
   * @param {*} param0
   */
  getEvent({
    resolve = noop,
    reject = noop,
    headers = {},
    event_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(HistoryActions.get_snapshot_request({ }));
      }
      return axios
        .get(`${API_URL}/events/?uuid=${ event_id }`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_snapshot_success({
              snapshot: response.data.results[0] || {}
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_snapshot_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get events types list
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @returns {function(*, *, {axios: *})}
   */
  getEventsTypes({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&is_manual=True`;

    return (dispatch, getState, { axios }) => {
      return axios
        .get(`${API_URL}/event-types/${query}`, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get histories
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param search
   * @param dispatchAction
   * @param params
   * @returns {function(*, *, {axios: *})}
   */
  getHistories({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    dispatchAction = true,
    params = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(HistoryActions.get_histories_request({ page, per_page }));
      }
      return axios
        .get(`${API_URL}/history/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_histories_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_histories_failure({ }));
          }

          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get company history
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param page
   * @param per_page
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getCompanyHistory({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    company_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&object_id=${company_id}&content_type__model=company`;

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(HistoryActions.get_company_history_request({ page, per_page }));
      }
      return axios
        .get(`${API_URL}/history/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_company_history_success({
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              }
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(HistoryActions.get_company_history_failure({ }));
          }

          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get event notes
   * @param {*} param0
   */
  getEventNotes({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    event_id,
    contentType = 58,
    type,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}&object_id=${ event_id }&content_type=${ contentType }`;

    return (dispatch, getState, { axios }) => {
      dispatch(HistoryActions.get_event_notes_request({ page, per_page, type }));
      return axios
        .get(`${API_URL}/notes/${query}`, autorization)
        .then((response) => {
          dispatch(HistoryActions.get_event_notes_success({
            results: response.data.results,
            type,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            }
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(HistoryActions.get_event_notes_failure({ type }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Add event note
   * @param {*} param0
   */
  addEventNote({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    type,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(HistoryActions.add_event_note_request({ type }));
      return axios
        .post(`${API_URL}/notes/create/`, data, autorization)
        .then((response) => {
          dispatch(HistoryActions.add_event_note_success({
            note: response.data,
            type,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(HistoryActions.add_event_note_failure({ type }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new History();
