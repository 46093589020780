import * as OrdersActions from '../actions/orders';
import { noop, getApiUrl } from '../utils/utils';
import { Logger } from '.';
import autoBind from 'auto-bind';
import { browserHistory } from 'react-router';

const API_URL = getApiUrl();

class Orders {
  /**
   * Class constructor
   */
  constructor() {
    autoBind(this);
  }

  /**
   * Delete order
   * @param {*} param0
   */
  deleteOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    order_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .delete(`${API_URL}/orders/${order_id}/delete/`, autorization)
        .then((response) => {
          dispatch(OrdersActions.delete_order_success({
            order_id,
          }));
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Complete order
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param order_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  completeOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    order_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(OrdersActions.complete_order_request({}));
      }
      return axios
        .post(`${API_URL}/orders/${order_id}/complete/`, data, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(OrdersActions.complete_order_success({ order_id, order: response.data }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(OrdersActions.complete_order_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Remove orders links
   * @param {*} param0
   */
  removeOrderUrl({
    link_id,
    resolve = noop,
    reject = noop,
    headers = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(OrdersActions.remove_order_link_request({}));
      return axios
        .delete(`${API_URL}/remove-link/${link_id}/`, autorization)
        .then((response) => {
          dispatch(OrdersActions.remove_order_link_success({ link_id }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(OrdersActions.remove_order_link_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Add order links
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  addOrderUrl({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(OrdersActions.add_order_link_request({}));
      }
      return axios
        .post(`${API_URL}/links-add/`, data, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(OrdersActions.add_order_link_success({ link: response.data }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(OrdersActions.add_order_link_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Add order note
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  addOrderNote({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(OrdersActions.add_order_note_request({}));
      }
      return axios
        .post(`${API_URL}/notes/create/`, data, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(OrdersActions.add_order_note_success({ note: response.data }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(OrdersActions.add_order_note_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Add files to order
   * @param resolve
   * @param reject
   * @param headers
   * @param order_id
   * @param data
   * @param files
   * @returns {function(*, *, {axios: *})}
   */
  addFilesToOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    order_id,
    data = {},
    files = [],
  }) {
    const autorization = {
      headers: { Authorization: `JWT ${headers.token}` },
    };

    return (dispatch, getState, { axios }) => {
      dispatch(OrdersActions.add_files_to_order_request({}));
      return axios
        .post(`${API_URL}/orders/${order_id}/files/`, data, autorization)
        .then((response) => {
          const newFiles = response.data.map((item) => {
            let file = {};

            files.map((elem) => {
              if (item.file_id === elem.id) {
                file = elem;
              }

              return elem;
            });

            return {
              file,
            };
          });

          dispatch(OrdersActions.add_files_to_order_success({ files: newFiles }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(OrdersActions.add_files_to_order_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get order
   * @param resolve
   * @param reject
   * @param headers
   * @param order_id
   * @returns {function(*, *, { axios: * })}
   */
  getOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    order_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(OrdersActions.get_order_request({
        order_not_found: false,
      }));

      return axios
        .get(`${API_URL}/orders/${order_id}/`, autorization)
        .then((response) => {
          dispatch(
            OrdersActions.get_order_success({
              order_not_found: false,
              order: response.data,
              order_loaded: true,
              order_id,
            }),
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            OrdersActions.get_order_failure({
              order_not_found: true,
              order_loaded: true,
            }),
          );
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Create amendment order
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  createAmendmentOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(OrdersActions.create_amendment_order_request({ }));
      return axios
        .post(`${API_URL}/companies/${company_id}/amendment/`, data, autorization)
        .then((response) => {
          dispatch(OrdersActions.create_amendment_order_success({
            amendment_company_order: response.data.order,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(OrdersActions.create_amendment_order_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Create renewal order
   * @param resolve
   * @param reject
   * @param headers
   * @param company_id
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  createRenewalOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    company_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(OrdersActions.create_amendment_order_request({ }));
      return axios
        .post(`${API_URL}/companies/${company_id}/renewal/`, data, autorization)
        .then((response) => {
          dispatch(OrdersActions.create_amendment_order_success({
            amendment_company_order: response.data.order,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(OrdersActions.create_amendment_order_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Add domains to order
   * @param resolve
   * @param reject
   * @param headers
   * @param order_id
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  addDomainsToOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    order_id,
    data = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      return axios
        .post(`${API_URL}/orders/${order_id}/domains/`, data, autorization)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * remove domains from order
   * @param {*} param0
   */
  removeDomainsFromOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    order_id,
    data = {},
  }) {
    return (dispatch, getState, { axios }) => {
      return axios
        .delete(`${API_URL}/orders/${order_id}/domains/`, {
          headers: {
            Authorization: `JWT ${headers.token}`,
          },
          data,
        }, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Create order
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  createOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(OrdersActions.post_orders_request({}));
      return axios
        .post(`${API_URL}/orders/create/`, data, autorization)
        .then((response) => {
          if (response.data['redirect_uri']) {
            window.location = response.data['redirect_uri']['url'];
          } else {
            dispatch(OrdersActions.post_orders_success({ order: response.data.order }));
            resolve(response);
          }
        })
        .catch((error) => {
          dispatch(OrdersActions.post_orders_failure({}));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Update order
   * @param resolve
   * @param reject
   * @param headers
   * @param data
   * @returns {function(*, *, {axios: *})}
   */
  updateOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    data = {},
    order_id,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      dispatch(OrdersActions.update_order_request({ order_id, data }));
      return axios
        .put(`${API_URL}/orders/${order_id}/`, data, autorization)
        .then((response) => {
          dispatch(OrdersActions.update_order_success({
            order_id,
            data,
            order: response.data.order,
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(OrdersActions.update_order_failure({ order_id, data }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get amendment order
   * @param resolve
   * @param reject
   * @param headers
   * @param order_id
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getAmendmentOrder({
    resolve = noop,
    reject = noop,
    headers = {},
    order_id,
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(OrdersActions.create_amendment_order_request({ }));
      }
      return axios
        .get(`${API_URL}/orders/${order_id}/`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(OrdersActions.create_amendment_order_success({
              amendment_company_order: response.data,
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(OrdersActions.create_amendment_order_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get Orders
   * @param resolve
   * @param reject
   * @param headers
   * @param page
   * @param per_page
   * @param params
   * @param dispatchAction
   * @returns {function(*, *, {axios: *})}
   */
  getOrders({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    params = {},
    dispatchAction = true,
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      if (dispatchAction) {
        dispatch(OrdersActions.get_orders_request({ params, page, per_page }));
      }
      return axios
        .get(`${API_URL}/orders/${query}`, autorization)
        .then((response) => {
          if (dispatchAction) {
            dispatch(OrdersActions.get_orders_success({
              params,
              page,
              per_page,
              results: response.data.results,
              pagination: {
                max_page: Math.ceil(response.data.count / per_page),
                count: response.data.count,
                per_page,
                page,
              },
            }));
          }
          resolve(response);
        })
        .catch((error) => {
          if (dispatchAction) {
            dispatch(OrdersActions.get_orders_failure({ }));
          }
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }

  /**
   * Get draft orders
   * @param {*} param0
   */
  getDraftOrders({
    resolve = noop,
    reject = noop,
    headers = {},
    page = 1,
    per_page = 10,
    params = {},
  }) {
    const autorization = { headers: { Authorization: `JWT ${headers.token}` } };
    let query = `?limit=${per_page}&offset=${(page - 1) * per_page}`;

    Object.keys(params).forEach((prop) => {
      if (params[prop]) {
        query += `&${prop}=${params[prop]}`;
      }
    });

    return (dispatch, getState, { axios }) => {
      dispatch(OrdersActions.get_draft_orders_request({ params, page, per_page }));
      return axios
        .get(`${API_URL}/orders/${query}`, autorization)
        .then((response) => {
          dispatch(OrdersActions.get_draft_orders_success({
            params,
            page,
            per_page,
            results: response.data.results,
            pagination: {
              max_page: Math.ceil(response.data.count / per_page),
              count: response.data.count,
              per_page,
              page,
            },
          }));
          resolve(response);
        })
        .catch((error) => {
          dispatch(OrdersActions.get_draft_orders_failure({ }));
          Logger.logResponseError(error);
          reject(error);
        });
    };
  }
}

export default new Orders();
